export enum PlanType {
  FREE = 'free',
  PREMIUM = 'premium',
}

export type PlanFeature = {
  comingSoon: boolean;
  description: string;
  featureLabel: string;
  isVisible: boolean;
  key: string;
  title: string;
  value: number;
};

export enum FeatureCategory {
  FORM_CREATION = 'FORM_CREATION',
  FORM_EXPERIENCE = 'FORM_EXPERIENCE',
  SHARING = 'SHARING',
  RESULTS = 'RESULTS',
  SMART_FORM = 'SMART_FORM',
  FORM_FLOW = 'FORM_FLOW',
  AUDIENCE = 'AUDIENCE',
}

export enum FeatureKeys {
  FORM_CREATION_LIMIT = 'FORM_CREATION_LIMIT',
  GENERATE_FORM_WITH_AI = 'GENERATE_FORM_WITH_AI',
  PREDEFINED_FORM_TEMPLATE = 'PREDEFINED_FORM_TEMPLATE',
  MONTHLY_SUBMISSION_LIMIT = 'MONTHLY_SUBMISSION_LIMIT',
  MAX_QUESTION_PER_FORM = 'MAX_QUESTION_PER_FORM',
  FORM_FILE_UPLOAD_SIZE = 'FORM_FILE_UPLOAD_SIZE',
  WELCOME_PAGE = 'WELCOME_PAGE',
  END_PAGE = 'END_PAGE',
  LIVE_PREVIEW = 'LIVE_PREVIEW',
  END_PAGE_CUSTOM_CTA = 'END_PAGE_CUSTOM_CTA',
  ALLOW_CLASSIC_FORM = 'ALLOW_CLASSIC_FORM',
  CUSTOM_FORM_THEME = 'CUSTOM_FORM_THEME',
  THEME_MODE = 'THEME_MODE',
  REMOVE_BRANDING = 'REMOVE_BRANDING',
  CUSTOM_BRAND_LOGO_IN_FORM = 'CUSTOM_BRAND_LOGO_IN_FORM',
  EMAIL_SUBMISSION_NOTIFICATION = 'EMAIL_SUBMISSION_NOTIFICATION',
  SMS_SUBMISSION_NOTIFICATION = 'SMS_SUBMISSION_NOTIFICATION',
  WHATS_APP_SUBMISSION_NOTIFICATION = 'WHATS_APP_SUBMISSION_NOTIFICATION',
  WEBHOOK_SUBMISSION_NOTIFICATION = 'WEBHOOK_SUBMISSION_NOTIFICATION',
  NOTIFICATION_ON_SUBMISSION = 'NOTIFICATION_ON_SUBMISSION',
  IN_PAGE_FORM_EMBED = 'IN_PAGE_FORM_EMBED',
  BOT_POP_FORM_EMBED = 'BOT_POP_FORM_EMBED',
  MULTI_FORM_LINK = 'MULTI_FORM_LINK',
  CUSTOM_DOMAIN = 'CUSTOM_DOMAIN',
  FORM_SCHEDULING = 'FORM_SCHEDULING',
  FORM_AI_SUMMARY_BY_QUESTION = 'FORM_AI_SUMMARY_BY_QUESTION',
  AI_SUMMARY_IN_DAY = 'AI_SUMMARY_IN_DAY',
  FORM_AI_SUMMARY = 'FORM_AI_SUMMARY',
  FORM_AI_INSIGHTS = 'FORM_AI_INSIGHTS',
  EXTERNAL_STORAGE = 'EXTERNAL_STORAGE',
  SHOW_PARTIAL_FORM_SUBMISSIONS = 'SHOW_PARTIAL_FORM_SUBMISSIONS',
  QUIZ_MODE = 'QUIZ_MODE',
  CALCULATED_FIELDS = 'CALCULATED_FIELDS',
  LOGIC_BASED_FORM = 'LOGIC_BASED_FORM',
  FORM_DATA_GOOGLE_SPREAD_SHEET = 'FORM_DATA_GOOGLE_SPREAD_SHEET',
  EXPORT_FORM_SUBMISSIONS = 'EXPORT_FORM_SUBMISSIONS',
  EXPORT_FORM_RESULTS_BY_QUESTION = 'EXPORT_FORM_RESULTS_BY_QUESTION',
  VIEW_FORM_TRANSCRIPT = 'VIEW_FORM_TRANSCRIPT',
  NOTIFY_AT_DAY = 'NOTIFY_AT_DAY',
  NUMBER_OF_WORKSPACE_CREATION = 'NUMBER_OF_WORKSPACE_CREATION',
  MAX_WORKSPACE_MEMBER = 'MAX_WORKSPACE_MEMBER',
  PREDEFINE_TONE_OF_VOICE = 'PREDEFINE_TONE_OF_VOICE',
  DEFAULT_FORM_THEME = 'DEFAULT_FORM_THEME',
  ALPHA_FEATURE = 'ALPHA_FEATURE',
  TAG_TRACKING = 'TAG_TRACKING',
  ADVANCED_FIELDS = 'ADVANCED_FIELDS',
  ADVANCED_EMBED = 'ADVANCED_EMBED',
  WEBHOOK_SUPPORT = 'WEBHOOK_SUPPORT',
  API_SUPPORT = 'API_SUPPORT',
}

export type Feature = {
  key: string;
  name: string;
  value: number;
  categoryKey: string;
  description: string;
};
