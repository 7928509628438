import { Warning } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

export default function CommonDeleteMessage({ message }: { message: string }) {
  return (
    <Flex align="center" gap={8}>
      <Warning size={18} weight="fill" color="var(--caution-on-surface)" />
      <Flex vertical>
        <Paragraph className="mb-0">{message}</Paragraph>
        <Paragraph className="mb-0">Delete anyway?</Paragraph>
      </Flex>
    </Flex>
  );
}
