import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { CaretLeft, Trash, UploadSimple } from '@phosphor-icons/react';
import {
  ConfigProvider,
  Flex,
  Form,
  Input,
  Popconfirm,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_SIZE,
  FORM_CHARACTER_LIMIT,
} from '../../../../common/constants';
import {
  commonUploadFile,
  hasFeatureAccess,
  validatePageBackgroundImage,
} from '../../../../common/utils';
import CommonOverlay from '../../../../components/common/CommonOverlay';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import SubscribeModal from '../../../../components/common/SubscribeModal';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useFeatureAccess from '../../../../hooks/useFeatureAccess';
import useRouter from '../../../../hooks/useRouter';
import { AppContextType } from '../../../../types/appContext.type';
import { FeatureName, Permissions } from '../../../../types/common.type';
import { FeatureKeys } from '../../../profile/profile.types';
import {
  FormFieldType,
  FormSchemaType,
  InputChangeTypes,
  PageLayoutType,
} from '../../form.types';
import { GET_PAGE_BG_URL, GET_SIGNED_URL_PAGES } from '../../graphql/queries';
import FormFieldsPagination from '../utils/FormFieldsPagination';
import PageLayoutOption from '../utils/PageLayoutOption';
import { CommonPageTypes } from './pages.types';

export const EditEndPage: React.FC<
  CommonPageTypes & {
    onChange: ({ id, path, value }: InputChangeTypes) => void;
    itemList: FormSchemaType[];
    paginationProps: {
      total: number;
      onChange: (item: FormSchemaType) => void;
    };
  }
> = ({ onBack, item, onChange, itemList, paginationProps }) => {
  const { params } = useRouter();
  const formId = params?.id as string;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [disableCTA, setDisableCTA] = useState<boolean>(true);
  const { checkFeatureAccess } = useFeatureAccess();
  const {
    state: { currentUser },
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const editAccess = hasPermission(Permissions.WRITE);

  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    useState(false);
  const [isUpgradeSuccessfulModalVisible, setIsUpgradeSuccessfulModalVisible] =
    useState(false);

  const [getSignedUrlMutate, { loading }] = useLazyQuery(GET_SIGNED_URL_PAGES, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [getImageURL] = useLazyQuery(GET_PAGE_BG_URL, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    if (item?.bg_image_url) {
      setFileList([
        {
          url: item.bg_image_url,
          uid: '-1',
          name: item?.bg_file_name as string,
          status: 'done',
        },
      ]);
    }
  }, []);

  useEffect(() => {
    checkFeatureAccess(FeatureKeys.END_PAGE_CUSTOM_CTA, () => {
      setDisableCTA(false);
    });
  }, []);

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.pageBgImage,
        ALLOWED_FILE_TYPES.pageBgImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      getSignedUrlMutate({
        variables: {
          data: {
            formId: formId as string,
            pageType: FormFieldType.END_PAGE,
            fileName: file?.name,
            contentLength: file?.size,
            questionId: item?.question_id as string,
          },
        },
        onCompleted: async (res) => {
          const signedUrl = res.getFormPageImageUploadSignedUrl?.signedUrl;
          const key = res.getFormPageImageUploadSignedUrl?.key;
          if (signedUrl && key) {
            const success = await commonUploadFile(signedUrl, file);
            if (success) {
              getImageURL({
                variables: {
                  data: {
                    imageKey: key,
                  },
                },
                onCompleted: (res) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['bg_image', 'bg_image_url', 'bg_file_name'],
                    value: [
                      key as string,
                      res.getFormStructurePagesImageUrl?.imageUrl as string,
                      file.name,
                    ],
                  });
                  onSuccess(null, file);
                },
              });
            } else {
              onError(new Error('Upload failed'));
            }
          }
        },
      });
    };

  // upload props for welcome page
  const uploadProps: UploadProps = {
    accept: ALLOWED_FILE_TYPES.pageBgImage,
    name: 'file',
    multiple: false,
    fileList,
    customRequest: handleUpload,
    onRemove: () => {
      onChange({
        id: item?.question_id || '',
        path: ['bg_image_url', 'bg_image'],
        value: ['', ''],
      });
    },
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      setFileList(info.fileList);
    },
    itemRender: (originNode, file, fileList, { remove }) => {
      return (
        <Flex align="center" justify="space-between" gap={8}>
          <Paragraph className="mb-0 text-content-primary">
            {file?.name}
          </Paragraph>
          <Flex gap={8} align="center">
            {/* commenting for future use */}
            {/* <CommonButton
              type="text"
              onClick={() => {
                remove();
              }}
            >
              Replace
            </CommonButton> */}
            <Popconfirm
              title="Are you sure, you want to delete?"
              okText="Yes"
              className="hover-show"
              onConfirm={(e) => {
                e?.stopPropagation();
                remove();
              }}
              onCancel={(e) => e?.stopPropagation()}
              placement="right"
              okButtonProps={{
                block: true,
                icon: <DeleteOutlined />,
                danger: true,
              }}
              cancelButtonProps={{ block: true }}
              icon={null}
            >
              <CommonButton
                shape="circle"
                type="text"
                shadow={false}
                icon={<Trash color="var(--danger-on-surface)" size={16} />}
              />
            </Popconfirm>
          </Flex>
        </Flex>
      );
    },
  };
  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
            colorLink: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Form disabled={!editAccess}>
          <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
            <CommonButton
              type="text"
              shape="circle"
              onClick={() => onBack()}
              shadow={false}
              disabled={false}
              icon={<CaretLeft color="var(--content-primary)" size={20} />}
            />
            <Paragraph className="mb-0 text-content-primary text-base semi-bold">
              End Page
            </Paragraph>
          </Flex>
          <div className="scroll-wrapper-body">
            <Flex
              gap={16}
              wrap
              justify="space-between"
              align="flex-start"
              className="mb-24"
            >
              <Flex vertical>
                <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                  Show end page
                </Paragraph>
                <Paragraph className="mb-0 text-content-tertiary medium">
                  Show a thank-you message after form submission.
                </Paragraph>
              </Flex>
              <CommonSwitch
                type="primary"
                checked={item?.is_display}
                onChange={(checked) =>
                  onChange({
                    id: item?.question_id || '',
                    path: ['is_display'],
                    value: [checked],
                  })
                }
              />
            </Flex>
            <Flex vertical gap={20} className="relative">
              <Form.Item label="Page Layout" labelCol={{ span: 24 }}>
                <Flex gap={12}>
                  <PageLayoutOption
                    value={item?.page_layout as PageLayoutType}
                    onChange={(val) => {
                      onChange({
                        id: item?.question_id || '',
                        path: ['page_layout'],
                        value: [val],
                      });
                    }}
                    disabled={!editAccess}
                  />
                </Flex>
              </Form.Item>
              <Form.Item label="Image (Up to 5MB)" labelCol={{ span: 24 }}>
                <Upload {...uploadProps}>
                  {fileList.length >= 1 ? null : (
                    <CommonButton
                      type="text"
                      icon={<UploadSimple />}
                      disabled={loading}
                    >
                      Upload
                    </CommonButton>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item label="Title" labelCol={{ span: 24 }}>
                <Input
                  placeholder="Type..."
                  value={item?.title}
                  onChange={(e) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['title'],
                      value: [e.target.value],
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Body" labelCol={{ span: 24 }}>
                <Input.TextArea
                  autoSize={{ minRows: 3 }}
                  placeholder="Type..."
                  value={item?.body}
                  onChange={(e) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['body'],
                      value: [e.target.value],
                    });
                  }}
                />
              </Form.Item>
              <Flex vertical>
                <Flex align="center" gap={8}>
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Button
                  </Paragraph>

                  {!hasFeatureAccess(
                    currentUser?.subscriptionPlan?.features,
                    FeatureKeys.END_PAGE_CUSTOM_CTA,
                  ) && (
                    <PremiumLogo
                      isSubscriptionModal
                      featureName={FeatureName.EndPageCta}
                    />
                  )}
                </Flex>
                <Paragraph className="mb-0 text-content-tertiary medium">
                  Customize button on end page
                </Paragraph>
              </Flex>
              <Flex vertical gap={16}>
                <Form.Item label="Button text" labelCol={{ span: 24 }}>
                  <Input
                    disabled={disableCTA || !editAccess}
                    placeholder="Enter button text here..."
                    value={item?.cta_name}
                    showCount
                    maxLength={FORM_CHARACTER_LIMIT.previewPageCTALimit}
                    onChange={(e) => {
                      onChange({
                        id: item?.question_id || '',
                        path: ['cta_name'],
                        value: [e.target.value],
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Button redirection link"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter link here..."
                    disabled={disableCTA || !editAccess}
                    value={item?.cta_link}
                    onChange={(e) => {
                      onChange({
                        id: item?.question_id || '',
                        path: ['cta_link'],
                        value: [e.target.value],
                      });
                    }}
                  />
                </Form.Item>
              </Flex>
              {item?.is_display === false && <CommonOverlay />}
            </Flex>
          </div>
          {itemList?.length > 0 && (
            <div className="absolute bottom-32 w-full">
              <FormFieldsPagination
                itemList={itemList}
                currentItem={item as FormSchemaType}
                total={paginationProps.total}
                onChange={(item) => paginationProps.onChange(item)}
              />
            </div>
          )}
          {isSubscriptionModalVisible && (
            <SubscribeModal
              visible={isSubscriptionModalVisible}
              onCancel={() => setIsSubscriptionModalVisible(false)}
              onUpgrade={() => {
                setIsSubscriptionModalVisible(false);
                setIsUpgradeSuccessfulModalVisible(true);
              }}
              featureName={FeatureName.EndPageCta}
            />
          )}
          {/* {isUpgradeSuccessfulModalVisible && (
            <UpgradeSuccessful
              isVisible={isUpgradeSuccessfulModalVisible}
              onClose={() => setIsUpgradeSuccessfulModalVisible(false)}
            />
          )} */}
        </Form>
      </ConfigProvider>
    </section>
  );
};
