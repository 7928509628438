import { Button, ButtonProps, ConfigProvider, Tooltip } from 'antd';
import { LegacyRef } from 'react';
import { COMING_SOON_TEXT } from '../../common/constants';
import { FeatureName } from '../../types/common.type';
import PremiumLogo from '../common/PremiumLogo';

export default function CommonButton({
  children,
  premium,
  className,
  shadow = true,
  isTooltip = false,
  gradientBorder = false,
  featureName,
  ...restProps
}: ButtonProps & {
  premium?: boolean;
  shadow?: boolean;
  isTooltip?: boolean;
  gradientBorder?: boolean;
  featureName?: FeatureName;
  ref?: LegacyRef<HTMLButtonElement | HTMLAnchorElement> | undefined;
}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#21282B',
        },

        components: {
          Button: {
            controlHeight: 40,
            controlHeightLG: 48,
            controlHeightSM: 32,
            contentFontSizeLG: 16,
            contentFontSize: 14,
            contentFontSizeSM: 12,
            fontWeight: 400,
            borderRadius: 50,
            borderRadiusLG: 50,
            borderRadiusSM: 50,
            paddingInline: 20,
            paddingBlock: 8,
            paddingInlineSM: 16,
            paddingBlockSM: 8,
            paddingInlineLG: 20,
            paddingBlockLG: 8,
            colorPrimaryHover: 'var(--surface-inverse-secondary)',
            defaultBg: 'var(--surface-primary)',
            colorBorder: 'transparent',
            defaultHoverBorderColor: 'transparent',
            defaultHoverBg: 'var(--surface-tertiary)',
            textHoverBg: 'var(--surface-tertiary)',
          },
        },
      }}
    >
      <Tooltip title={isTooltip ? COMING_SOON_TEXT : ''}>
        <Button
          className={`${className ?? ''} ${!shadow ? 'shadow-hidden' : ''} ${
            gradientBorder ? 'gradient-border' : ''
          }`}
          {...restProps}
        >
          {children}
          {/* hide premium logo if feature is coming soon */}
          {premium && !isTooltip && <PremiumLogo featureName={featureName} />}
        </Button>
      </Tooltip>
    </ConfigProvider>
  );
}
