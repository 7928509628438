import { useLazyQuery, useMutation } from '@apollo/client';
import { CopySimple, Eye, EyeSlash, Plus, Trash } from '@phosphor-icons/react';
import { Flex, Table, Tooltip } from 'antd';
import { TableProps } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { filter, isEmpty, map } from 'lodash';
import { useEffect, useState } from 'react';
import {
  ApiKeysResponse,
  ApiKeysSortOnField,
  SortOrder,
  WorkspaceMemberRoles,
  WsSubscriptions,
} from '../../__generated__/graphql';
import { initialPaginationFilter } from '../../common/constants';
import {
  copyToClipboard,
  handleGraphQlError,
  handleGraphQlSuccess,
  hasFeatureAccess,
} from '../../common/utils';
import CommonDeleteModal from '../../components/common/CommonDeleteModal';
import CreateApiKeyModal from '../../components/common/CreateApiKeyModal';
import SubscribeModal from '../../components/common/SubscribeModal';
import CommonButton from '../../components/primitives/CommonButton';
import useFeatureAccess from '../../hooks/useFeatureAccess';
import useRouter from '../../hooks/useRouter';
import { FeatureName } from '../../types/common.type';
import { REMOVE_API_KEY } from '../workspace/graphql/mutations';
import { GET_WORKSPACE_DETAILS } from '../workspace/graphql/queries';
import { GET_API_KEYS } from './graphql/queries';
import { FeatureKeys } from './profile.types';
dayjs.extend(relativeTime);

export default function APIListings() {
  const apiKeyLimit = 5;
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [currentDeleteKey, setCurrentDeleteKey] = useState<string>('');
  const { params } = useRouter();
  const [apiDetails, setApiDetails] = useState<ApiKeysResponse>();
  const [featureKeys, setFeatureKeys] = useState<WsSubscriptions>();
  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
  } = useFeatureAccess();

  const initialVariables = {
    filter: initialPaginationFilter,
    sort: {
      sortBy: SortOrder.Desc,
      sortOn: ApiKeysSortOnField.CreatedAt,
    },
  };

  const [fetchApiDetails, { loading }] = useLazyQuery(GET_API_KEYS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setApiDetails({
        data: res.apiKeys?.data,
        count: res.apiKeys?.count,
      });
    },
    onError: () => {},
  });

  const [fetchWorkspaceDetails, { loading: fetchLoading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setFeatureKeys(
          res.workspace?.data?.wsSubscriptions?.[0] as WsSubscriptions,
        );
      },
      onError: () => {},
    },
  );

  const [removeApiKeyMutate, { loading: removeLoading }] = useMutation(
    REMOVE_API_KEY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        handleGraphQlSuccess(res.deleteApiKey?.message);
        setApiDetails({
          ...apiDetails,
          count: apiDetails?.count ? apiDetails.count - 1 : 1,
          data: {
            ...apiDetails?.data,
            apiKeys: filter(
              apiDetails?.data?.apiKeys,
              (item) => item?.id !== currentDeleteKey,
            ),
          },
        });
        setIsDeleteModal(false);
      },
      onError: (err) => {
        handleGraphQlError(err?.message);
      },
    },
  );

  const handleToggleVisibility = (id: string, visible: boolean) => {
    const updatedList = map(apiDetails?.data?.apiKeys, (item) => {
      if (item?.id === id) {
        return { ...item, visible: visible ? false : true };
      }
      return item;
    });
    setApiDetails({
      ...apiDetails,
      data: {
        ...apiDetails?.data,
        apiKeys: updatedList,
      },
    });
  };

  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value: string) => (
        <Paragraph className="mb-0 text-sm text-content-primary">
          {value}
        </Paragraph>
      ),
    },
    {
      title: 'API key',
      dataIndex: 'key',
      key: 'key',
      render: (text: string, record) => {
        const { id, visible } = record;
        const textLength = text?.length;

        return (
          <Flex
            gap={8}
            align="center"
            className="api-key-input"
            justify="space-between"
          >
            <Tooltip title={textLength > 20 && visible ? text : ''}>
              <Paragraph className="mb-0 text-sm text-content-primary">
                {visible
                  ? textLength > 20
                    ? `${text.slice(0, 20)}...`
                    : text
                  : '*'.repeat(textLength).slice(0, 20)}
              </Paragraph>
            </Tooltip>
            <Flex align="center" gap={8}>
              {record?.visible ? (
                <Tooltip title="Hide">
                  <EyeSlash
                    size={18}
                    color="var(--system-blue-dark)"
                    className="cursor-pointer"
                    onClick={() => {
                      handleToggleVisibility(id, visible);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Show">
                  <Eye
                    size={18}
                    color="var(--system-blue-dark)"
                    className="cursor-pointer"
                    onClick={() => {
                      handleToggleVisibility(id, visible);
                    }}
                  />
                </Tooltip>
              )}

              <Tooltip title="Copy">
                <CopySimple
                  size={18}
                  color="var(--system-blue-dark)"
                  className="cursor-pointer"
                  onClick={() => {
                    copyToClipboard(text);
                  }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (value: string) => {
        return (
          <Paragraph className="mb-0 text-sm text-content-primary">
            {dayjs(value).fromNow()}
          </Paragraph>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => (
        <Flex justify="flex-end">
          <CommonButton
            type="text"
            shape="circle"
            shadow={false}
            onClick={() => {
              setCurrentDeleteKey(value);
              setIsDeleteModal(true);
            }}
            disabled={removeLoading}
            icon={<Trash color="var(--danger-on-surface)" size={20} />}
          />
        </Flex>
      ),
    },
  ];

  const handleOnOk = () => {
    removeApiKeyMutate({
      context: {
        headers: {
          'x-workspace-id': params.id,
        },
      },
      variables: {
        where: {
          apiKeyId: currentDeleteKey,
        },
      },
    });
  };

  const fetchWorkspaceSettings = () => {
    if (params?.id) {
      fetchWorkspaceDetails({
        variables: {
          where: {
            workspaceUuid: params.id,
          },
        },
        context: {
          headers: {
            'x-workspace-id': params.id,
          },
        },
      });
    }
  };

  const handleOnUpgrade = () => {
    handleUpgradeSuccess();
    fetchApiDetails({
      variables: initialVariables,
      context: {
        headers: {
          'x-workspace-id': params.id,
        },
      },
    });
    fetchWorkspaceSettings();
  };

  useEffect(() => {
    if (params?.id) {
      fetchApiDetails({
        variables: initialVariables,
        context: {
          headers: {
            'x-workspace-id': params.id,
          },
        },
      });
      fetchWorkspaceSettings();
    }
  }, [params?.id]);

  const isOwner =
    apiDetails?.data?.workspaceMember?.role === WorkspaceMemberRoles.Owner;

  const hasAPICreationAccess = hasFeatureAccess(
    featureKeys?.subscription?.features,
    FeatureKeys.API_SUPPORT,
  );

  return (
    <div className="api-keys-wrapper">
      <Flex gap={8} justify="space-between" className="mb-16">
        <Flex gap={6} vertical>
          <Paragraph className="mb-0 text-base text-content-primary medium">
            API Keys
          </Paragraph>
          <Paragraph className="mb-0 text-content-tertiary text-sm">
            Manage your API keys here to integrate ZINQ seamlessly. You can
            create maximum of {apiKeyLimit} API keys.{' '}
            <Link underline>Read documentation</Link>
          </Paragraph>
        </Flex>
        {apiDetails &&
          !isEmpty(featureKeys?.subscription?.features) &&
          isOwner && (
            <CommonButton
              type="text"
              icon={<Plus color="var(--content-primary)" />}
              disabled={
                apiDetails?.count === apiKeyLimit || loading || fetchLoading
              }
              premium={!hasAPICreationAccess}
              onClick={() => {
                checkFeatureAccess(
                  FeatureKeys.API_SUPPORT,
                  () => {
                    setIsCreateModal(true);
                  },
                  featureKeys?.subscription?.features,
                );
              }}
            >
              Create API key ({`${apiDetails?.count}/${apiKeyLimit}`})
            </CommonButton>
          )}
      </Flex>
      <Table
        className="domain-record-table"
        dataSource={isOwner ? apiDetails?.data?.apiKeys || [] : []}
        columns={columns}
        pagination={false}
        loading={loading}
        locale={{
          emptyText: loading
            ? 'Loading...'
            : !isOwner
              ? "You don't have permissions to view this page"
              : 'No keys found',
        }}
      />
      {isDeleteModal && (
        <CommonDeleteModal
          open={isDeleteModal}
          title="Delete API key"
          okText="Delete"
          content="Are you sure you want to delete this API key? This action cannot be undone."
          onClose={() => {
            setIsDeleteModal(false);
          }}
          onOk={() => {
            handleOnOk();
          }}
          cancelButtonProps={{
            disabled: removeLoading,
          }}
          okButtonProps={{
            danger: true,
            loading: removeLoading,
            type: 'text',
          }}
        />
      )}

      {isCreateModal && (
        <CreateApiKeyModal
          isVisible={isCreateModal}
          title="Create new API key"
          onCancel={() => {
            setIsCreateModal(false);
          }}
          onConfirm={() => {
            setIsCreateModal(false);
            fetchApiDetails({
              variables: initialVariables,
              context: {
                headers: {
                  'x-workspace-id': params.id,
                },
              },
            });
          }}
        />
      )}

      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleOnUpgrade}
          featureName={FeatureName.PartialForm}
        />
      )}
    </div>
  );
}
