import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CaretDown,
  CaretLeft,
  CopySimple,
  FacebookLogo,
  LinkedinLogo,
  Plus,
  QrCode,
  Trash,
  TwitterLogo,
  UploadSimple,
} from '@phosphor-icons/react';
import {
  Col,
  ColorPicker,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { includes, isEmpty, isEqual, split, trim } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FormShare,
  FormShareType,
  FormShareUpdateInput,
  FormSubmissionType,
} from '../../../../__generated__/graphql';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as IframeIcon } from '../../../../assets/svg/embed-type/iframe.svg';
import { ReactComponent as PopoverIcon } from '../../../../assets/svg/embed-type/popover.svg';
import { ReactComponent as PopupIcon } from '../../../../assets/svg/embed-type/popup.svg';
import { ReactComponent as SlideTabIcon } from '../../../../assets/svg/embed-type/slide-tab.svg';
import { ReactComponent as SliderIcon } from '../../../../assets/svg/embed-type/slider.svg';

import { Color } from 'antd/es/color-picker';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_SIZE,
  DEFAULT_AUTO_SAVE_TIME,
  defaultEmbedConfig,
  DROPDOWN_LIST,
  embedList,
  FORM_CHARACTER_LIMIT,
  ROUTES,
} from '../../../../common/constants';
import {
  commonUploadFile,
  copyToClipboard,
  getFormLink,
  getFormTypeOptions,
  handleGraphQlSuccess,
  handleSocialShare,
  hasFeatureAccess,
  validatePageBackgroundImage,
} from '../../../../common/utils';
import CommonQRCodeModal from '../../../../components/common/CommonQRCodeModal';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useRouter from '../../../../hooks/useRouter';
import {
  AppActionType,
  AppContextType,
} from '../../../../types/appContext.type';
import { Permissions } from '../../../../types/common.type';
import { EmbedTypes, SocialShareType } from '../../../form/form.types';
import { FeatureKeys } from '../../../profile/profile.types';
import { DELETE_FORM_SHARE, UPDATE_FORM_SHARE } from '../../graphql/mutations';
import {
  GET_SHARE_DETAIL,
  GET_SIGNED_URL_OG_SHARE_IMAGE,
} from '../../graphql/queries';
import CommonDeleteMessage from '../utils/CommonDeleteMessage';
import DimensionInput from '../utils/DimensionInput';
import FormLoading from '../utils/FormLoading';
import CustomizeDomain from './CustomizeDomain';
import { CommonPageTypes } from './pages.types';
import { iconOptionMapping } from './widget/embed-utils';

export default function EditShare({ onBack }: CommonPageTypes) {
  const [details, setDetails] = useState<FormShare>();
  const [cloneDetails, setCloneDetails] = useState<FormShare>();
  const [embedType, setEmbedType] = useState<EmbedTypes>();
  const [form] = Form.useForm();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const scrollRef = useRef(null);
  const [link, setLink] = useState<string>('');
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const { params, navigate } = useRouter();
  const shareId = params?.shareId as string;
  const [count, setCount] = useState(0);
  const [step, setStep] = useState(0);
  const [hidePopover, setHidePopover] = useState(false);

  const ColorPickerText = (color: Color) => (
    <Flex gap={12} align="center">
      <Paragraph className="text-content-secondary mb-0">
        {color.toHexString()}
      </Paragraph>
      <CaretDown size={20} color="car(--content-primary)" />
    </Flex>
  );

  const {
    state: { currentUser },
    dispatch,
    hasPermission,
  } = useContext(AppContext) as AppContextType;

  // embed types
  const menuItems = [
    {
      label: 'Iframe',
      value: EmbedTypes.IFRAME,
      icon: <IframeIcon />,
    },
    {
      label: 'Popup',
      value: EmbedTypes.POPUP,
      icon: <PopupIcon />,
    },
    {
      label: 'Slider',
      value: EmbedTypes.SLIDER,
      icon: <SliderIcon />,
    },
    {
      label: 'Popover',
      value: EmbedTypes.POPOVER,
      icon: <PopoverIcon />,
    },
    {
      label: 'Side tab',
      value: EmbedTypes.SIDE_TAB,
      icon: <SlideTabIcon />,
    },
  ];

  const hasShareAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.MULTI_FORM_LINK,
  );

  const hasAlphaFeature = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.ALPHA_FEATURE,
  );

  const hasAdvancedEmbedAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.ADVANCED_EMBED,
  );

  const editAccess = hasPermission(Permissions.WRITE);

  const [updateFormShare] = useMutation(UPDATE_FORM_SHARE, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const handleEmbedTypeChange = (type: EmbedTypes) => {
    const allFields = form.getFieldsValue();
    setEmbedType(type);
    setDetails({
      ...details,
      embedConfigs: {
        ...defaultEmbedConfig,
        type: type,
        button: {
          ...(defaultEmbedConfig?.button || {}),
          // ...allFields?.embedConfigs?.button,
          ...(allFields?.embedConfigs?.button &&
          Object.keys(allFields.embedConfigs.button).some(
            (key) => allFields.embedConfigs.button[key] !== undefined,
          )
            ? allFields.embedConfigs.button
            : {}),
        },
        isWelcomePage: allFields?.embedConfigs?.isWelcomePage,
        width: allFields?.embedConfigs?.width,
        height: allFields?.embedConfigs?.height,
        ...(allFields?.embedConfigs?.position !== undefined && {
          position: allFields?.embedConfigs?.position,
        }),
        ...(allFields?.embedConfigs?.popupSize !== undefined && {
          popupSize: allFields?.embedConfigs?.popupSize,
        }),
      },
      formType: allFields?.formType,
    });
  };

  const [getShareDetails, { loading }] = useLazyQuery(GET_SHARE_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const formData = res.formShare?.data;
      setCount(res.formShare?.count || 0);
      if (formData) {
        setDetails(formData as FormShare);
        setCloneDetails(formData as FormShare);
        setEmbedType(formData?.embedConfigs?.type);
        setLink(
          getFormLink(
            formData?.key || '',
            {
              customLink: formData.customLink!,
              domainType: formData.domainType!,
            },
            formData?.embedConfigs,
          ),
        );
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            previewType: formData?.formType as FormSubmissionType,
            shares: [formData],
          },
        });
        if (formData.formType === FormSubmissionType.Classic) {
          // Remove Popover from the embed list
          setHidePopover(true);
          if (formData.embedConfigs?.type === EmbedTypes.POPOVER) {
            handleEmbedTypeChange(EmbedTypes.IFRAME);
          }
        } else {
          setHidePopover(false);
        }
      }
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [getSignedUrlMutate, { loading: uploadLoading }] = useLazyQuery(
    GET_SIGNED_URL_OG_SHARE_IMAGE,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );

  const [deleteFormShare, { loading: deleteLoading }] = useMutation(
    DELETE_FORM_SHARE,
    {
      onError: () => {},
    },
  );

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.ogShareImage,
        ALLOWED_FILE_TYPES.ogShareImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      getSignedUrlMutate({
        variables: {
          data: {
            formShareId: shareId,
            imageType: file?.type,
            fileName: file?.name,
            contentLength: file?.size,
          },
        },
        onCompleted: async (res) => {
          const signedUrl = res.getFormOgImageUploadSignedUrl?.signedUrl;
          const key = res.getFormOgImageUploadSignedUrl?.key;
          if (signedUrl && key) {
            const success = await commonUploadFile(signedUrl, file);
            if (success) {
              onSuccess(key, file);
            } else {
              onError(new Error('Upload failed'));
            }
          }
        },
      });
    };

  const fetchShareDetails = () => {
    getShareDetails({
      variables: {
        where: {
          formShareId: shareId,
        },
      },
    });
  };

  useEffect(() => {
    if (shareId) {
      fetchShareDetails();
    }
  }, [shareId]);

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const allFields: FormShareUpdateInput = form.getFieldsValue();
    if (allFields.formType === FormSubmissionType.Classic) {
      // Remove Popover from the embed list
      setHidePopover(true);
      if (allFields.embedConfigs?.type === EmbedTypes.POPOVER) {
        handleEmbedTypeChange(EmbedTypes.IFRAME);
      }
    } else {
      setHidePopover(false);
    }
    dispatch({
      type: AppActionType.setFormSettings,
      data: {
        previewType: allFields?.formType as FormSubmissionType,
        shares: [allFields],
      },
    });
    setLink(
      getFormLink(
        details?.key || '',
        {
          customLink: details!.customLink!,
          domainType: details!.domainType!,
        },
        allFields?.embedConfigs,
      ),
    ); // immediate update link for copy button
    timerRef.current = setTimeout(() => {
      updateFormShare({
        variables: {
          where: {
            formShareId: shareId,
          },
          data: {
            ...allFields,
            title: trim(allFields.title || '') ? allFields.title : 'Untitled',
          },
        },
        onCompleted: () => {
          setCloneDetails(allFields);
        },
      });
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  const handleDeleteImage = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    form.setFieldsValue({
      ogThumbnail: null,
    });
    handleAutoSave();
  };
  // upload props for welcome page
  const uploadProps: UploadProps = {
    accept: ALLOWED_FILE_TYPES.ogShareImage,
    name: 'file',
    multiple: false,
    fileList,
    customRequest: handleUpload,
    // onRemove: () => {},
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      setFileList(info.fileList);
    },
    itemRender: (originNode, file) => {
      let blobUrl = '';
      if (file?.originFileObj) {
        blobUrl = URL.createObjectURL(file.originFileObj);
      }

      return (
        <Flex justify="space-between" align="center" className="relative">
          <Image
            src={blobUrl || file?.url}
            width={100}
            preview={{
              toolbarRender: () => null,
              mask: (
                <Flex gap={8}>
                  <EyeOutlined />
                  {editAccess && (
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(file);
                      }}
                    />
                  )}
                </Flex>
              ),
            }}
          />
        </Flex>
      );
    },
  };

  useEffect(() => {
    form.setFieldsValue(details);
    const url = form.getFieldValue('ogThumbnailUrl');
    if (url) {
      setFileList([
        {
          uid: '-1',
          name: split(url, '/').pop() || '',
          status: 'done',
          url: url,
        },
      ]);
    }
  }, [details, form]);

  const handleQRCode = () => {
    setQrModalVisible(true);
  };

  const handleCloseQRModal = () => {
    setQrModalVisible(false);
  };

  const handleDelete = (id: string) => {
    deleteFormShare({
      variables: {
        where: {
          formShareId: id,
        },
      },
      onCompleted: (res) => {
        handleGraphQlSuccess(res.deleteFormShare?.message);
        onBack();
      },
    });
  };

  useEffect(() => {
    if (!isEqual(details, cloneDetails) && details) {
      handleAutoSave();
      dispatch({
        type: AppActionType.setFormSettings,
        data: {
          previewType: details?.formType as FormSubmissionType,
          shares: [details],
        },
      });
    }
  }, [details]);

  const filteredMenuItems = hidePopover
    ? menuItems.filter((item) => item.value !== EmbedTypes.POPOVER)
    : menuItems;

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: '0 0 4px 0',
              itemMarginBottom: 0,
              fontWeightStrong: 500,
            },
            Input: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
              colorText: 'var(--content-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            InputNumber: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBgContainer: '#ff0000',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        {step === 0 ? (
          <div className="scroll-wrapper sharing">
            {!loading ? (
              <Form
                autoComplete="off"
                initialValues={details}
                form={form}
                onFieldsChange={handleAutoSave}
                className="edit-share-form"
                disabled={!editAccess}
                layout="vertical"
              >
                <Flex
                  gap={8}
                  wrap
                  align="center"
                  justify="space-between"
                  className="scroll-wrapper-header"
                >
                  <Flex gap={12} align="center">
                    <CommonButton
                      type="text"
                      onClick={() => onBack()}
                      shape="circle"
                      shadow={false}
                      disabled={false}
                      icon={
                        <CaretLeft color="var(--content-primary)" size={20} />
                      }
                    />
                    <Form.Item name="title" className="w-full">
                      <Input
                        variant="borderless"
                        className="text-m semi-bold text-content-primary"
                        placeholder="Type here..."
                      />
                    </Form.Item>
                  </Flex>
                  {count <= 2 && !hasShareAccess ? null : (
                    <Flex wrap gap={24} align="center">
                      <Tooltip title="Delete">
                        <Popconfirm
                          title={
                            <CommonDeleteMessage message="This will deactivate already shared links." />
                          }
                          okText="Yes"
                          placement="right"
                          className="hover-show"
                          onConfirm={() => handleDelete(details?.id as string)}
                          okButtonProps={{
                            block: true,
                            icon: <DeleteOutlined />,
                            danger: true,
                            loading: deleteLoading,
                          }}
                          cancelButtonProps={{
                            block: true,
                            disabled: deleteLoading,
                          }}
                          icon={null}
                        >
                          <CommonButton
                            type="text"
                            shape="circle"
                            shadow={false}
                            loading={deleteLoading}
                            icon={
                              <Trash
                                color="var(--danger-on-surface)"
                                size={20}
                              />
                            }
                          />
                        </Popconfirm>
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
                <div className="scroll-wrapper-body">
                  <Row gutter={[12, 24]}>
                    <Col lg={14} xl={16}>
                      <Input placeholder="Link here..." value={link} disabled />
                    </Col>
                    <Col lg={10} xl={8}>
                      <CommonButton
                        type="text"
                        icon={
                          <CopySimple
                            color="var(--content-primary)"
                            size={16}
                          />
                        }
                        block
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(link);
                        }}
                        disabled={false}
                      >
                        {details?.type === FormShareType.Embed
                          ? 'Copy Code'
                          : 'Copy Link'}
                      </CommonButton>
                    </Col>
                    {details?.type === FormShareType.Link && (
                      <Col span={24}>
                        <Flex gap={12} justify="flex-start" align="center">
                          <Tooltip title="Share on Facebook">
                            <CommonButton
                              type="text"
                              shape="circle"
                              icon={<FacebookLogo size={24} />}
                              onClick={() =>
                                handleSocialShare(
                                  SocialShareType.FACEBOOK,
                                  link,
                                )
                              }
                              disabled={false}
                            />
                          </Tooltip>
                          <Tooltip title="Share on Twitter">
                            <CommonButton
                              type="text"
                              shape="circle"
                              icon={<TwitterLogo size={24} />}
                              onClick={() =>
                                handleSocialShare(SocialShareType.TWITTER, link)
                              }
                              disabled={false}
                            />
                          </Tooltip>
                          <Tooltip title="Share on LinkedIn">
                            <CommonButton
                              type="text"
                              shape="circle"
                              icon={<LinkedinLogo size={24} />}
                              onClick={() =>
                                handleSocialShare(
                                  SocialShareType.LINKEDIN,
                                  link,
                                )
                              }
                              disabled={false}
                            />
                          </Tooltip>
                          <Tooltip title="Generate QR Code">
                            <CommonButton
                              type="text"
                              shape="circle"
                              icon={<QrCode size={24} />}
                              onClick={handleQRCode}
                              disabled={false}
                            />
                          </Tooltip>
                        </Flex>
                      </Col>
                    )}
                    {details?.type === FormShareType.Link && (
                      <Col span={24}>
                        <Flex gap={8} justify="space-between" align="center">
                          <Flex vertical gap={2}>
                            <Flex gap={4} align="center">
                              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                                Customize Domain
                              </Paragraph>
                              <PremiumLogo isTooltip />
                            </Flex>
                            <Paragraph className="mb-0 text-content-tertiary medium">
                              Set up a custom domain for your forms.
                            </Paragraph>
                          </Flex>
                          <CommonButton
                            type="default"
                            onClick={() => setStep(1)}
                          >
                            Customize
                          </CommonButton>
                        </Flex>
                      </Col>
                    )}
                    <Col span={24}>
                      <Flex gap={8} justify="space-between" align="center">
                        <Flex vertical gap={2}>
                          <Flex gap={4} align="center">
                            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                              UTM tracking
                            </Paragraph>
                            <PremiumLogo isTooltip />
                          </Flex>
                          <Paragraph className="mb-0 text-content-tertiary medium">
                            Track your form traffic.
                          </Paragraph>
                        </Flex>
                        <CommonButton
                          type="default"
                          icon={<Plus />}
                          disabled
                          isTooltip
                        >
                          Add values
                        </CommonButton>
                      </Flex>
                    </Col>
                    <Col span={24}>
                      <Divider className="mb-0 mt-0" dashed />
                    </Col>
                    <Col span={24}>
                      <Flex vertical gap={4}>
                        <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                          Form Type
                        </Paragraph>
                        <Form.Item name="formType">
                          <Segmented
                            className="app-segments"
                            options={getFormTypeOptions(!!hasAlphaFeature)}
                            disabled={!editAccess}
                          />
                        </Form.Item>
                      </Flex>
                    </Col>
                    {details?.type === FormShareType.Link && (
                      <>
                        <Col span={24}>
                          <Flex
                            gap={8}
                            align="center"
                            justify="space-between"
                            wrap
                          >
                            <Flex vertical gap={2}>
                              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                                Thumbnail/Preview Image
                              </Paragraph>
                              <Paragraph className="mb-0 text-content-tertiary medium">
                                Set the image displayed when sharing your form
                                link.
                              </Paragraph>
                              <Paragraph className="mb-0 text-content-tertiary medium">
                                (Recommended size: 1200x630 in px)
                              </Paragraph>
                            </Flex>
                            <Form.Item
                              name="ogThumbnail"
                              valuePropName="file"
                              getValueFromEvent={(e) => {
                                return e?.fileList?.length
                                  ? e?.file?.response
                                  : '';
                              }}
                            >
                              <Upload {...uploadProps}>
                                {fileList.length >= 1 ? null : (
                                  <CommonButton
                                    type="text"
                                    icon={
                                      <UploadSimple
                                        size={16}
                                        weight="fill"
                                        color="var(--content-primary)"
                                      />
                                    }
                                  >
                                    Upload
                                  </CommonButton>
                                )}
                              </Upload>
                            </Form.Item>
                          </Flex>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Meta Title"
                            name="ogTitle"
                            labelCol={{ span: 24 }}
                          >
                            <Input placeholder="Type here..." />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Meta Description"
                            name="ogDescription"
                            labelCol={{ span: 24 }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 2 }}
                              placeholder="Type here..."
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {details?.type === FormShareType.Embed && (
                      <Col span={24}>
                        <Flex vertical gap={4}>
                          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                            Embed As
                          </Paragraph>

                          <Form.Item name={['embedConfigs', 'type']}>
                            <div className="embed-type-grid">
                              {filteredMenuItems.map((item) => (
                                <div
                                  key={item.value}
                                  className={`embed-type-card ${embedType === item.value ? 'active' : ''}
                                ${!editAccess ? 'disabled' : ''}`}
                                  onClick={() => {
                                    if (editAccess) {
                                      handleEmbedTypeChange(item.value);
                                    } else {
                                      return undefined;
                                    }
                                  }}
                                >
                                  <div className="icon-wrapper">
                                    {item.icon}
                                  </div>
                                  <Paragraph className="text-center mb-0 text-content-secondary medium">
                                    {item.label}
                                  </Paragraph>
                                </div>
                              ))}
                            </div>
                          </Form.Item>
                        </Flex>
                      </Col>
                    )}

                    {includes(embedList, embedType) && (
                      <div className="embed-control-wrapper">
                        <Flex
                          gap={8}
                          justify="space-between"
                          align={!isEmpty(fileList) ? 'flex-start' : 'center'}
                          wrap
                          vertical={!isEmpty(fileList)}
                          ref={scrollRef}
                          className="full-width"
                        >
                          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                            Show welcome screen
                          </Paragraph>
                          <Form.Item name={['embedConfigs', 'isWelcomePage']}>
                            <CommonSwitch type="primary" />
                          </Form.Item>
                        </Flex>
                        <Form.Item
                          name={['embedConfigs', 'position']}
                          label="Position"
                          hidden={embedType !== EmbedTypes.SLIDER}
                          className="full-width w-50"
                        >
                          <Select
                            options={DROPDOWN_LIST.embedSliderPositions}
                            suffixIcon={
                              <CaretDown
                                size={20}
                                color="var(--content-secondary)"
                              />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={['embedConfigs', 'popupSize']}
                          label="Popup size"
                          hidden={embedType !== EmbedTypes.POPUP}
                          className="full-width w-50"
                        >
                          <Select
                            options={DROPDOWN_LIST.embedPopupSizeList}
                            suffixIcon={
                              <CaretDown
                                size={20}
                                color="var(--content-secondary)"
                              />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={['embedConfigs', 'button', 'icon']}
                          label="Choose icon"
                          hidden={
                            embedType !== EmbedTypes.POPOVER &&
                            embedType !== EmbedTypes.SIDE_TAB
                          }
                          className="w-50 full-width"
                        >
                          <Select
                            popupMatchSelectWidth={false}
                            options={DROPDOWN_LIST.embedIconList}
                            labelRender={(data) => (
                              <Flex align="center" gap={8}>
                                <span className="flex">
                                  {iconOptionMapping?.[data.value]}
                                </span>
                                <Paragraph className="mb-0">
                                  {data?.label}
                                </Paragraph>
                              </Flex>
                            )}
                            optionRender={(option) => (
                              <Flex
                                align="center"
                                justify="space-between"
                                gap={8}
                                wrap
                              >
                                <Flex align="center" gap={8}>
                                  <Flex>
                                    {iconOptionMapping?.[option.data.value]}
                                  </Flex>
                                  <Paragraph className="text-content-primary mb-0">
                                    {option.data.label}
                                  </Paragraph>
                                </Flex>
                              </Flex>
                            )}
                            suffixIcon={
                              <CaretDown
                                size={20}
                                color="var(--content-secondary)"
                              />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={['embedConfigs', 'button', 'text']}
                          label="Button text"
                          hidden={
                            embedType !== EmbedTypes.POPUP &&
                            embedType !== EmbedTypes.SLIDER &&
                            embedType !== EmbedTypes.SIDE_TAB
                          }
                        >
                          <Input
                            maxLength={
                              FORM_CHARACTER_LIMIT.embedButtonTextLimit
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={['embedConfigs', 'button', 'fontSize']}
                          label="Font size"
                          hidden={
                            embedType !== EmbedTypes.POPUP &&
                            embedType !== EmbedTypes.SLIDER &&
                            embedType !== EmbedTypes.SIDE_TAB
                          }
                        >
                          <Select
                            options={DROPDOWN_LIST.fontSizeList}
                            suffixIcon={
                              <CaretDown
                                size={20}
                                color="var(--content-secondary)"
                              />
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          name={['embedConfigs', 'button', 'radius']}
                          label="Corner roundness"
                          hidden={
                            embedType === EmbedTypes.IFRAME ||
                            embedType == EmbedTypes.POPOVER
                          }
                          className="w-50 full-width"
                        >
                          <InputNumber min={0} className="w-full" />
                        </Form.Item>

                        <Form.Item
                          name={['embedConfigs', 'button', 'backgroundColor']}
                          label="Button color"
                          getValueFromEvent={(color) => color.toHexString()}
                          hidden={embedType === EmbedTypes.IFRAME}
                        >
                          <ColorPicker
                            size="large"
                            showText={ColorPickerText}
                          />
                        </Form.Item>
                        <Form.Item
                          name={['embedConfigs', 'button', 'iconColor']}
                          label="Icon color"
                          getValueFromEvent={(color) => color.toHexString()}
                          hidden={
                            embedType !== EmbedTypes.POPOVER &&
                            embedType !== EmbedTypes.SIDE_TAB
                          }
                        >
                          <ColorPicker
                            size="large"
                            showText={ColorPickerText}
                          />
                        </Form.Item>
                        <DimensionInput
                          name={['embedConfigs', 'width']}
                          label="Width"
                          hidden={embedType !== EmbedTypes.IFRAME}
                        />
                        <DimensionInput
                          name={['embedConfigs', 'height']}
                          label="Height"
                          hidden={embedType !== EmbedTypes.IFRAME}
                        />
                      </div>
                    )}
                  </Row>
                </div>
              </Form>
            ) : (
              <FormLoading />
            )}
          </div>
        ) : details ? (
          <CustomizeDomain
            details={details}
            onBack={(step) => {
              setStep(step);
              fetchShareDetails();
            }}
          />
        ) : null}
      </ConfigProvider>
      {qrModalVisible && (
        <CommonQRCodeModal
          qrModalVisible={qrModalVisible}
          handleCloseQRModal={handleCloseQRModal}
          link={link}
        />
      )}
    </>
  );
}
