import { useMutation } from '@apollo/client';
import { CaretDown } from '@phosphor-icons/react';
import { Form, Spin, Statistic } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { FormThemeMode, UserProfileData } from '../../__generated__/graphql';
import { DROPDOWN_LIST, ROUTES, TEMPLATE_ID } from '../../common/constants';
import Logo from '../../components/common/Logo';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { FormField } from '../form/editor/fields/FormField';
import { FormFieldType } from '../form/form.types';
import { ErrorTypes } from './auth.types';
import { UPDATE_USER_PROFILE } from './graphql/mutations';

const { Countdown } = Statistic;
const OTP_MODE = process.env.REACT_APP_OTP_MODE === 'true' ? true : false;

const Onboarding = () => {
  const [metaForm] = useForm();
  const [errors, setErrors] = useState<ErrorTypes>({});
  const { navigate, location } = useRouter();

  const [updateUserProfileMutate, { loading }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      onError() {},
    },
  );

  const handleOnboardingFinish = () => {
    const templateId = localStorage.getItem(TEMPLATE_ID);
    if (templateId) {
      navigate(`${ROUTES.CREATE_FORM_SLUG}/${templateId}`);
    } else {
      navigate(ROUTES.FORM_TEMPLATES);
    }
  };

  const handleMetaFormFinish = (formValues?: UserProfileData) => {
    if (formValues) {
      updateUserProfileMutate({
        variables: { data: formValues },
        onCompleted: () => {
          handleOnboardingFinish();
        },
      });
    } else {
      handleOnboardingFinish();
    }
  };

  return (
    <>
      <CommonButton
        className="fixed top-16 right-24"
        onClick={() => handleMetaFormFinish()}
        size="small"
        disabled={loading}
      >
        Skip
      </CommonButton>
      <section className="auth-wrapper">
        <div className="auth-form">
          <div className="flex justify-center">
            <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
          </div>
          <Spin spinning={loading}>
            <Form
              form={metaForm}
              name="meta-form"
              onFinish={handleMetaFormFinish}
              layout="vertical"
              scrollToFirstError
            >
              <FormField
                name="industry"
                type={FormFieldType.SELECT}
                label="Which industry do you belong to?"
                apiErrors={errors}
                options={DROPDOWN_LIST.industryList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'industry-container',
                    ) as HTMLElement,
                }}
              />
              <div id="industry-container"></div>
              <FormField
                name="position"
                type={FormFieldType.SELECT}
                label="What is your current position or title?"
                apiErrors={errors}
                options={DROPDOWN_LIST.positionList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'position-container',
                    ) as HTMLElement,
                }}
              />
              <div id="position-container"></div>
              <FormField
                name="discover"
                type={FormFieldType.SELECT}
                label="How did you discover ZinQ?"
                apiErrors={errors}
                options={DROPDOWN_LIST.discoverList}
                inputProps={{
                  placeholder: 'Select',
                  showSearch: true,
                  size: 'large',
                  suffixIcon: (
                    <CaretDown size={18} color="var(--content-quarternary)" />
                  ),
                  getPopupContainer: () =>
                    document.getElementById(
                      'discover-container',
                    ) as HTMLElement,
                }}
              />
              <div id="discover-container"></div>
              <Form.Item>
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  {loading ? 'Creating...' : 'Start Creating'}
                </CommonButton>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </section>
    </>
  );
};

export default Onboarding;
