import {
  ArrowClockwise,
  CaretDown,
  CaretUp,
  Circle,
  Desktop,
  DeviceMobileCamera,
} from '@phosphor-icons/react';
import { Button, ConfigProvider, Flex, Segmented } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { cloneDeep, lowerCase } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  FormSubmissionType,
  FormThemeMode,
  FormThemeVariation,
} from '../../../__generated__/graphql';
import { BrowserStatusBarSvg, MobileIndicatorSvg } from '../../../assets/icons';
import {
  COMPANY_INFO,
  DEFAULT_TIME_FORMAT,
  getThemeBackground,
} from '../../../common/constants';
import { getFormTypeOptions } from '../../../common/utils';
import PreviewTour from '../../../components/common/tours/PreviewTour';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import MadeWithZinq from '../../branding/MadeWithZinq';
import {
  FormPageType,
  FormPreviewType,
  FormSchemaType,
  FormThemeType,
  PageLayoutType,
  Palette,
  SubscriptionPlans,
} from '../form.types';
import CallPreview from '../view/CallPreview';
import ClassicUIPreview from '../view/ClassicUIPreview';
import ConversationalUIPreview from '../view/ConversationalUIPreview';
import WebCallPreview from '../view/WebCallPreview';
import AIProcessingForm from './utils/AIProcessingForm';
import FormLoading from './utils/FormLoading';
import SwitchingMode from './utils/SwitchingMode';

let timeout: NodeJS.Timeout | null = null;

export default function FormPreviewMode({ showTabs }: { showTabs: boolean }) {
  const [currentItem, setCurrentItem] = useState<FormSchemaType>();
  const [previewType, setPreviewType] = useState<FormPreviewType>(
    FormPreviewType.desktop,
  );
  const [switching, setSwitching] = useState<boolean>(false);
  const {
    state: {
      appState,
      activeThemeVariationId,
      themeList,
      activeThemeId,
      systemThemeMode,
      activeMode,
      removeBranding,
      formItems,
      formSettings,
      triggerTour,
    },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [selectedTheme, setSelectedTheme] = useState<FormThemeVariation>();
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formItems?.items && formItems?.activeId) {
      if (formItems.items?.length > 0) {
        const itemObj = formItems?.items?.find(
          (item: FormSchemaType) => item.question_id === formItems?.activeId,
        );
        setCurrentItem(cloneDeep(itemObj));
      }
    }
  }, [formItems]);

  useEffect(() => {
    if (activeThemeVariationId && themeList?.length > 0) {
      setSelectedTheme(
        themeList
          ?.filter((val) => val.id === activeThemeId)?.[0]
          ?.themeVariations?.filter(
            (val) => val?.id === activeThemeVariationId,
          )?.[0] || {},
      );
    }
  }, [activeThemeVariationId, themeList]);

  const mode = activeMode === FormThemeMode.Auto ? systemThemeMode : activeMode;
  const activeTheme = selectedTheme?.colorPalette?.[lowerCase(mode as string)];

  const renderNextPrevButtons = () => {
    if (
      currentItem?.page_type === FormPageType.WELCOME_PAGE ||
      currentItem?.page_type === FormPageType.END_PAGE
    ) {
      return null;
    }
    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: activeTheme?.fontFamily,
          },
          components: {
            Button: {
              defaultColor: activeTheme?.textColor,
              defaultBg: 'transparent',
              defaultBorderColor: activeTheme?.textColor,
              defaultHoverBg: activeTheme?.textColor,
              defaultHoverColor: activeTheme?.bgPrimary,
            },
          },
        }}
      >
        <Flex gap={4} className="floating-next-prev-buttons">
          <Button
            icon={<CaretUp size={24} weight="fill" />}
            className={mode === FormThemeMode.Light ? 'dark' : 'light'}
          />
          <Button
            icon={<CaretDown size={24} weight="fill" />}
            htmlType="submit"
            className={mode === FormThemeMode.Light ? 'dark' : 'light'}
          />
        </Flex>
      </ConfigProvider>
    );
  };

  const renderStartAgainButton = () => {
    if (
      currentItem?.page_type === FormPageType.WELCOME_PAGE ||
      currentItem?.page_type === FormPageType.END_PAGE
    ) {
      return null;
    }

    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: activeTheme?.fontFamily,
          },
          components: {
            Button: {
              defaultColor: activeTheme?.textColor,
              defaultBg: 'transparent',
              defaultBorderColor: activeTheme?.textColor,
              defaultHoverBg: activeTheme?.textColor,
              defaultHoverColor: activeTheme?.textColor,
            },
          },
        }}
      >
        <Flex
          gap={4}
          className={`floating-start-again-button ${previewType === FormPreviewType.mobile ? 'mobile' : ''}`}
        >
          <Button
            icon={<ArrowClockwise size={14} weight="fill" />}
            className={mode === FormThemeMode.Light ? 'dark' : 'light'}
            shape="round"
          >
            Start Again
          </Button>
        </Flex>
      </ConfigProvider>
    );
  };

  const renderFormPreview = () => {
    switch (true) {
      case appState?.formCreateError:
        return (
          <Paragraph
            className="mb-0 semi-bold text-m"
            style={{ color: activeTheme?.textColor }}
          >
            Add a question to see the preview of it here.
          </Paragraph>
        );
      case appState?.formFetchLoading:
        return <FormLoading />;
      case appState?.formCreateLoading:
        return <AIProcessingForm type="PREVIEW" activeTheme={activeTheme} />;
      case formItems.items?.length === 0:
        return (
          <Paragraph
            className="mb-0 semi-bold text-m"
            style={{ color: activeTheme?.textColor }}
          >
            Add a question to see the preview of it here.
          </Paragraph>
        );
      default:
        return (
          <>
            {formSettings?.previewType === FormSubmissionType.Classic && (
              <div
                className={`classic-form-preview scrollbar-none ${previewType === FormPreviewType.mobile ? 'mobile' : ''} ${currentItem?.nested && currentItem?.nested?.length > 2 ? 'nested-form' : ''}`}
              >
                {formItems?.items && (
                  <ClassicUIPreview
                    itemList={
                      currentItem?.page_type === FormPageType.END_PAGE ||
                      currentItem?.page_type === FormPageType.WELCOME_PAGE
                        ? [currentItem]
                        : formItems?.items?.filter(
                            (item: FormSchemaType) =>
                              item.page_type !== FormPageType.WELCOME_PAGE &&
                              item.page_type !== FormPageType.END_PAGE,
                          )
                    }
                    activeItemId={currentItem?.question_id || ''}
                    isMobile={previewType === FormPreviewType.mobile}
                    isUpload={false}
                    autoFocus={false}
                    themeMode={mode as FormThemeMode}
                    onFinish={() => {
                      // do nothing for preview
                    }}
                    activeTheme={activeTheme}
                    handleCTAClick={() => {
                      // do nothing for preview
                    }}
                    showFooterButtons={true}
                  />
                )}
                {previewType === FormPreviewType.mobile && (
                  <span className="floating-bottom-bar"></span>
                )}
              </div>
            )}
            {formSettings?.previewType === FormSubmissionType.Conversation &&
              currentItem && (
                <div
                  className={`conversational-form-preview scrollbar-none ${previewType === FormPreviewType.mobile ? 'mobile' : ''} ${currentItem?.nested && currentItem?.nested?.length > 2 ? 'nested-form' : ''}`}
                >
                  {selectedTheme && (
                    <ConversationalUIPreview
                      item={currentItem}
                      isMobile={previewType === FormPreviewType.mobile}
                      isUpload={false}
                      autoFocus={false}
                      themeMode={mode as FormThemeMode}
                      onFinish={() => {
                        // do nothing for preview
                      }}
                      activeTheme={activeTheme}
                      handleCTAClick={() => {
                        // do nothing for preview
                      }}
                      showFooterButtons={true}
                    />
                  )}
                  {previewType === FormPreviewType.mobile && (
                    <span className="floating-bottom-bar"></span>
                  )}
                </div>
              )}
            {formSettings?.previewType === FormSubmissionType.Voice && (
              <div className="conversational-form-preview voice-form-preview">
                <CallPreview
                  activeTheme={
                    {
                      bgImage: null,
                      bgPrimary: '#FFFFFF',
                      textColor: '#586165',
                      fontFamily: 'Inter',
                      bgSecondary: '#ffffff',
                      gradientDegree: 0,
                    } as Palette
                  }
                />
              </div>
            )}

            {formSettings?.previewType === FormSubmissionType.WebCall && (
              <div className="conversational-form-preview voice-form-preview">
                <WebCallPreview activeTheme={activeTheme} />
              </div>
            )}

            {formSettings?.previewType === FormSubmissionType.Chat && (
              <div className="conversational-form-preview voice-form-preview">
                <WebCallPreview activeTheme={activeTheme} />
              </div>
            )}
          </>
        );
    }
  };

  const hasAlphaFeature =
    currentUser.subscriptionPlan?.features?.find(
      ({ key }: { key: string }) => key === SubscriptionPlans.ALPHA_FEATURE,
    )?.value === true;

  const isSpecialPage =
    currentItem?.page_type === FormPageType.WELCOME_PAGE ||
    currentItem?.page_type === FormPageType.END_PAGE;

  const hasBackgroundImage =
    currentItem?.page_layout === PageLayoutType.PAGE_FILLED &&
    currentItem?.bg_image_url;

  const themeBackgroundStyle = getThemeBackground(
    selectedTheme?.type as FormThemeType,
    selectedTheme?.colorPalette?.[lowerCase(mode as string)],
  );

  const style =
    isSpecialPage && hasBackgroundImage
      ? {}
      : formSettings?.previewType === FormSubmissionType.Voice
        ? {
            backgroundColor: '#FFFFFF',
          }
        : themeBackgroundStyle;

  const renderHeader = () => {
    switch (previewType) {
      case FormPreviewType.desktop:
        return (
          <Flex className="mock-desktop-header" align="center">
            <Flex align="center" gap={6}>
              <Circle weight="fill" color="#FF5F57" size={16} />
              <Circle weight="fill" color="#FFBD2E" size={16} />
              <Circle weight="fill" color="#28CA42" size={16} />
            </Flex>
            <Flex align="center" gap={6} flex={2} justify="center">
              <BrowserStatusBarSvg />
            </Flex>
          </Flex>
        );
      case FormPreviewType.mobile:
        return (
          <Flex
            className="mock-mobile-header"
            align="center"
            gap={6}
            justify="space-between"
          >
            <Paragraph className="text-neutrals mb-0 text-m">
              {dayjs().format(DEFAULT_TIME_FORMAT)}
            </Paragraph>
            <MobileIndicatorSvg />
          </Flex>
        );

      default:
        return '';
    }
  };

  const hideControlButtons =
    appState?.formCreateLoading ||
    formItems.items?.length === 0 ||
    formSettings?.previewType === FormSubmissionType.Classic;

  return (
    <section>
      {showTabs && (
        <Flex className="mb-16" gap={16} justify="space-between" align="center">
          <Segmented
            className="app-segments w-auto"
            value={formSettings?.previewType}
            options={getFormTypeOptions(hasAlphaFeature)}
            onChange={(value) => {
              if (timeout) {
                clearTimeout(timeout);
              }
              setSwitching(true);
              dispatch({
                type: AppActionType.setFormSettings,
                data: {
                  previewType: value,
                },
              });
              timeout = setTimeout(() => {
                setSwitching(false);
              }, COMPANY_INFO.switchingDuration);
            }}
          />
          <Segmented
            className="app-segments rounded w-auto"
            ref={previewRef}
            value={previewType}
            options={[
              {
                value: FormPreviewType.desktop,
                icon: (
                  <Desktop
                    size={18}
                    color="var(--content-tertiary)"
                    weight="fill"
                  />
                ),
              },
              {
                value: FormPreviewType.mobile,
                icon: (
                  <DeviceMobileCamera
                    size={18}
                    color="var(--content-tertiary)"
                    weight="fill"
                  />
                ),
              },
            ]}
            onChange={(value) => {
              setPreviewType(value);
            }}
          />
        </Flex>
      )}
      <Flex
        className={`form-preview-mode-wrapper ${showTabs ? 'tab-height' : ''}`}
        vertical
      >
        {renderHeader()}
        {switching && formSettings?.previewType ? (
          <div
            className={`form-preview-mode scrollbar-none bg-surface-primary ${previewType === FormPreviewType.desktop ? 'desktop' : 'mobile'}`}
          >
            <SwitchingMode type={formSettings.previewType} />
          </div>
        ) : (
          <div
            className={`form-preview-mode scrollbar-none bg-surface-primary ${previewType === FormPreviewType.desktop ? 'desktop' : 'mobile'}`}
            style={style}
          >
            {isSpecialPage && hasBackgroundImage ? (
              <>
                <img
                  src={currentItem?.bg_image_url}
                  alt="preview-welcome-page"
                  className="preview-bg-image"
                />
                <div className="img-overlay" />
              </>
            ) : null}
            {!hideControlButtons && renderNextPrevButtons()}
            {renderFormPreview()}
            {!hideControlButtons && renderStartAgainButton()}
            {mode && !removeBranding && (
              <MadeWithZinq
                mode={mode}
                activeTheme={activeTheme}
                link={process.env.REACT_APP_WEBSITE_BASE_URL}
              />
            )}
            {triggerTour?.preview && (
              <PreviewTour
                refs={[previewRef]}
                open={triggerTour?.preview}
                onOpenChange={() => {}}
              />
            )}
          </div>
        )}
      </Flex>
    </section>
  );
}
