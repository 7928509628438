import { gql } from '../../../__generated__';

export const UPSERT_WEBHOOK = gql(`
 mutation UpsertWebhook($where: FormUniqueInput!, $data: UpsertWebhookData!) {
  upsertWebhook(where: $where, data: $data) {
    message
  }
}`);

export const TEST_WEBHOOK = gql(`
  mutation TestWebhook($where: FormUniqueInput!) {
  testWebhook(where: $where) {
    message
  }
}`);
