import { useLazyQuery } from '@apollo/client';
import { includes } from 'lodash';
import React, { createContext, useReducer } from 'react';
import {
  FormSubmissionType,
  FormThemeMode,
  User,
  WorkspaceMemberRoles,
} from './__generated__/graphql';
import client from './apollo';
import api from './common/api';
import {
  REFRESH_TOKEN,
  ROLES,
  ROUTES,
  TEMPLATE_ID,
  TOKEN,
  USER,
  USER_WORKSPACE_ID,
  WORKSPACE_VERIFY_ID,
} from './common/constants';
import { GET_CURRENT_USER } from './modules/auth/graphql/queries';
import {
  AppAction,
  AppActionType,
  AppContextType,
  AppState,
} from './types/appContext.type';
import { ChildrenPropType, Permissions } from './types/common.type';

const getLoggedInUser = (): User | null => {
  const loggedInUser = localStorage.getItem(USER);
  return loggedInUser ? JSON.parse(loggedInUser) : null;
};

const initialState: AppState = {
  currentUser: getLoggedInUser() || {},
  authenticated: !!localStorage.getItem(TOKEN),
  authToken: localStorage.getItem(TOKEN),
  redirectRoute: ROUTES.MAIN,
  // adding common loader state for app
  appState: {
    formCreateLoading: false,
    formFetchLoading: false,
    formCreateError: false,
  },
  themeList: [],
  activeThemeId: '',
  activeThemeVariationId: '',
  activeMode: FormThemeMode.Auto,
  removeBranding: false,
  formItems: {
    items: [],
    activeId: '',
  },
  formSettings: {
    previewType: FormSubmissionType.Conversation,
  },
  triggerTour: {
    editor: false,
    preview: false,
  },
  workspaceDetails: {},
};

const reducer = (state: AppState, action: AppAction) => {
  switch (action?.type) {
    case AppActionType.setCurrentUser:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      if (!localStorage.getItem(USER_WORKSPACE_ID) && user?.currentWorkspace) {
        localStorage.setItem(
          USER_WORKSPACE_ID,
          user?.currentWorkspace?.uuid || '',
        );
      }
      // localStorage.setItem(
      //   USER_WORKSPACE_ID,
      //   user?.currentWorkspace?.uuid ||
      //     state?.currentUser?.currentWorkspace?.uuid ||
      //     '',
      // );
      return { ...state, currentUser: { ...state?.currentUser, ...user } };
    case AppActionType.setCurrentRole:
      return { ...state, currentRole: action.data };
    case AppActionType.setRedirectRoute:
      return { ...state, redirectRoute: action.data };
    case AppActionType.userWorkspaces:
      return { ...state, workspaceDetails: action.data };
    case AppActionType.setSystemThemeMode:
      return { ...state, systemThemeMode: action.data };
    case AppActionType.triggerTour:
      return {
        ...state,
        triggerTour: { ...state?.triggerTour, ...action?.data },
      };
    case AppActionType.logout:
      delete api?.defaults?.headers?.common?.Authorization;
      localStorage.clear();
      client?.clearStore();
      return {
        ...state,
        authenticated: false,
        authToken: null,
        refreshToken: null,
        user: {},
        workspaceList: [],
        workspaceDetails: {},
      };
    case AppActionType.setAuthenticated:
      return { ...state, authenticated: action.data };
    case AppActionType.setAppThemes:
      return { ...state, themeList: action.data || [] };
    case AppActionType.setActiveThemeIds:
      return { ...state, ...action?.data };
    case AppActionType.setFormSettings:
      // eslint-disable-next-line no-case-declarations
      const formSettings = action.data || {};
      return {
        ...state,
        formSettings: { ...state?.formSettings, ...formSettings },
      };
    case AppActionType.setFormItems:
      return {
        ...state,
        formItems: { ...action.data },
      };

    case AppActionType.refetchForm:
      return {
        ...state,
        refetchForm: { ...action?.data },
      };
    case AppActionType.setAuthToken:
      localStorage.setItem(TOKEN, action.data ?? '');
      return { ...state, authToken: action.data };
    case AppActionType.setAppState:
      // eslint-disable-next-line no-case-declarations
      const data = action.data || {};
      return {
        ...state,
        appState: { ...state?.appState, ...data },
      };
    case AppActionType.setRefreshToken:
      localStorage.setItem(REFRESH_TOKEN, action.data ?? '');
      return {
        ...state,
        refreshToken: action.data,
      };
    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | null>(null);

const AppContextProvider: React.FC<ChildrenPropType> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fetchCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',

    onError: () => {},
  });

  const getToken = () => localStorage.getItem(TOKEN) || null;
  const getWorkspaceId = () => localStorage.getItem(USER_WORKSPACE_ID) || null;
  const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
  const getTemplateId = () => localStorage.getItem(TEMPLATE_ID);
  const getWorkspaceVerificationId = () =>
    localStorage.getItem(WORKSPACE_VERIFY_ID);
  const getCurrentUser = () =>
    localStorage?.getItem(USER)
      ? JSON?.parse(localStorage.getItem(USER) ?? '')
      : {};

  const isAuthenticated = () => state.authenticated;

  const initializeAuth = (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
    route?: string,
  ) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    const templateId = getTemplateId();
    const workspaceVerificationId = getWorkspaceVerificationId();
    const isOnboarding = route === ROUTES.ONBOARDING; // check if onboarding is remaining

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: AppActionType.setAuthToken, data: token });
      dispatch({ type: AppActionType.setRefreshToken, data: refresh });
      dispatch({ type: AppActionType.setAuthenticated, data: true });
      dispatch({ type: AppActionType.setCurrentUser, data: user });
      dispatch({
        type: AppActionType.setRedirectRoute,
        data: !isOnboarding
          ? workspaceVerificationId
            ? `${ROUTES.VERIFY_WORKSPACE_INVITE}/${workspaceVerificationId}`
            : templateId
              ? `${ROUTES.CREATE_FORM_SLUG}/${templateId}`
              : route
          : workspaceVerificationId
            ? `${ROUTES.VERIFY_WORKSPACE_INVITE}/${workspaceVerificationId}`
            : route,
      });
    }
  };

  const updateCurrentUser = (uuid: string, callback: () => void) => {
    fetchCurrentUser({
      context: {
        headers: {
          'x-workspace-id': uuid,
        },
      },
      onCompleted: (res) => {
        localStorage.setItem(USER_WORKSPACE_ID, uuid);
        dispatch({
          type: AppActionType.setCurrentUser,
          data: { ...state.currentUser, ...res.currentUser },
        });
        callback();
      },
    });
  };

  const hasPermission = (requiredPermission: Permissions) => {
    const userRole =
      state?.currentUser?.currentWorkspace?.workspaceMembers?.[0]?.role;
    if (!userRole) return false;
    return includes(ROLES?.[userRole]?.permissions, requiredPermission);
  };

  const getUserRole = () => {
    return state?.currentUser?.currentWorkspace?.workspaceMembers?.[0]
      ?.role as WorkspaceMemberRoles;
  };

  const value: AppContextType = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUser,
    getWorkspaceId,
    hasPermission,
    updateCurrentUser,
    getUserRole,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
