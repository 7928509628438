import { useLazyQuery } from '@apollo/client';
import { CaretDown, Phone, WhatsappLogo } from '@phosphor-icons/react';
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { Accent, AiTone, Gender } from '../../../__generated__/graphql';
import { DROPDOWN_LIST } from '../../../common/constants';
import { formValidation, handleGraphQlSuccess } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import {
  InitiateVoiceFormSubmissionInputType,
  Palette,
  VoiceMedium,
} from '../form.types';
import { INITIATE_VOICE_CALL } from '../graphql/queries';

export default function CallPreview({ activeTheme }: { activeTheme: Palette }) {
  const [form] = useForm();
  const { params } = useRouter();
  const formId = params?.id as string;
  const [isLoading, setIsLoading] = useState(false);
  const [isWtsLoading, setIsWtsLoading] = useState(false);
  const [initiateVoiceCallMutate, { loading }] = useLazyQuery(
    INITIATE_VOICE_CALL,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ width: 150 }}
        options={DROPDOWN_LIST.countryList}
        showSearch
        suffixIcon={<CaretDown size={18} color={activeTheme?.textColor} />}
      ></Select>
    </Form.Item>
  );

  const handleFinish = (formValues: InitiateVoiceFormSubmissionInputType) => {
    const { prefix, phone, ...restFormValues } = formValues;
    const payload = {
      ...restFormValues,
      formId: formId,
      phoneNumber: `${prefix}${phone}`,
    };
    // initiateVoiceCallMutate({
    //   variables: {
    //     data: payload,
    //   },
    //   onCompleted: (res) => {
    //     handleGraphQlSuccess(res.initiateVoiceFormSubmission?.message);
    //     form.resetFields();
    //   },
    // });
    setIsLoading(true);

    fetch(
      'https://voice-call-form-submission-dev.morning-union-20af.workers.dev/api/v1/make-call',
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        handleGraphQlSuccess(res.message);
        form.resetFields();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleWhatsappButton = () => {
    const { prefix, phone, ...restFormValues } = form.getFieldsValue();
    const payload = {
      ...restFormValues,
      formId: formId,
      toNumber: `${prefix}${phone}`,
      templateId: 'HX88f5ce74807aa3f605e702911ed7abcc',
    };

    setIsWtsLoading(true);

    fetch(
      'https://whats-app-form-submission-dev.morning-union-20af.workers.dev/send-message',
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        handleGraphQlSuccess(res.message);
        form.resetFields();
      })
      .finally(() => {
        setIsWtsLoading(false);
      });
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: 'var(--surface-secondary)',
          colorPrimary: '#a9aeb1',
          fontFamily: activeTheme?.fontFamily,
        },
        components: {
          Form: {
            labelColor: activeTheme?.textColor,
            labelFontSize: 14,
            verticalLabelPadding: '10px 0',
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Segmented: {
            trackBg: activeTheme?.textColor,
            itemColor: activeTheme?.bgPrimary,
            itemSelectedColor: activeTheme?.textColor,
            itemSelectedBg: activeTheme?.bgPrimary,
          },
          Input: {
            borderRadius: 16,
            // colorBorder: 'var(--border-primary)',
            colorText: activeTheme?.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme?.textColor,
          },
          InputNumber: {
            borderRadius: 16,
            colorText: activeTheme?.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme?.textColor,
          },
          Select: {
            colorText: activeTheme?.textColor,
            optionActiveBg: activeTheme?.bgPrimary,
            colorBgElevated: activeTheme?.bgPrimary,
            optionSelectedColor: activeTheme?.bgPrimary,
            optionSelectedBg: activeTheme?.textColor,
            colorBorder: activeTheme?.textColor,
            selectorBg: 'transparent',
          },
          Button: {
            colorPrimary: activeTheme?.textColor,
            colorPrimaryHover: activeTheme?.textColor,
            colorTextLightSolid: activeTheme?.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={{
          gender: Gender.Male,
          accent: Accent.American,
          tone: AiTone.Witty,
          language: VoiceMedium.English,
          prefix: '+91',
        }}
        onFinish={handleFinish}
      >
        <section className="form-selected-item">
          <Row gutter={[16, 24]}>
            {/* <Col lg={24}>
              <Form.Item label="Gender" name="gender">
                <Segmented
                  className="app-segments w-auto"
                  options={[
                    {
                      value: Gender.Male,
                      label: 'Male',
                      icon: <GenderMale size={18} />,
                    },
                    {
                      value: Gender.Female,
                      label: 'Female',
                      icon: <GenderFemale size={18} />,
                    },
                  ]}
                  onChange={(val) => {
                    form.setFieldValue('gender', val);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item label="accent" name="accent">
                <Segmented
                  className="app-segments w-auto"
                  options={[
                    {
                      value: Accent.American,
                      label: 'American',
                      icon: <CurrencyDollar size={18} />,
                    },
                    {
                      value: Accent.Indian,
                      label: 'Indian',
                      icon: <CurrencyInr size={18} />,
                    },
                  ]}
                  onChange={(val) => {
                    form.setFieldValue('accent', val);
                  }}
                />
              </Form.Item>
            </Col> */}
            <Col lg={12}>
              <Form.Item
                label="Language"
                name="language"
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch
                  className="w-full"
                  placeholder="Select"
                  optionFilterProp="label"
                  options={DROPDOWN_LIST.voiceMediumList}
                  suffixIcon={
                    <CaretDown size={18} color={activeTheme?.textColor} />
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={20}>
              <Form.Item
                label="Name"
                name="name"
                labelCol={{ span: 24 }}
                rules={[formValidation.required]}
              >
                <Input placeholder="Type here..." maxLength={30} />
              </Form.Item>
            </Col>
            <Col lg={20}>
              <Form.Item
                label="Phone number"
                name="phone"
                labelCol={{ span: 24 }}
                rules={[formValidation.required]}
              >
                <InputNumber
                  addonBefore={prefixSelector}
                  precision={0}
                  controls={false}
                  style={{ width: '100%' }}
                  placeholder="Type here..."
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Flex gap={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={isLoading}
                  size="large"
                  icon={<Phone size={18} weight="fill" />}
                >
                  Call Me
                </Button>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultColor: 'var(--neutral-00)',
                        defaultBg: '#25D366',
                        defaultBorderColor: '#25D366',
                        defaultHoverBg: activeTheme?.bgPrimary,
                        defaultHoverColor: activeTheme?.textColor,
                      },
                    },
                  }}
                >
                  <Button
                    type="default"
                    shape="round"
                    loading={isWtsLoading}
                    size="large"
                    icon={<WhatsappLogo size={18} weight="fill" />}
                    onClick={handleWhatsappButton}
                  >
                    Whatsapp Me
                  </Button>
                </ConfigProvider>
              </Flex>
            </Col>
          </Row>
        </section>
      </Form>
    </ConfigProvider>
  );
}
