import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import { trim } from 'lodash';
import React from 'react';
import { FORM_CHARACTER_LIMIT, MODAL_SIZE } from '../../common/constants';
import {
  formValidation,
  handleGraphQlError,
  handleGraphQlSuccess,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { GENERATE_API_KEY } from '../../modules/workspace/graphql/mutations';
import CommonButton from '../primitives/CommonButton';

type CreateApiKeyModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
};

const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  title,
}) => {
  const [form] = Form.useForm();
  const { params } = useRouter();

  const [generateApiKeyMutate, { loading }] = useMutation(GENERATE_API_KEY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      onConfirm();
      handleGraphQlSuccess(res.generateApiKey?.message);
    },
    onError: (err) => {
      handleGraphQlError(err?.message);
    },
  });

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        generateApiKeyMutate({
          context: {
            headers: {
              'x-workspace-id': params.id,
            },
          },
          variables: {
            data: { name: trim(values?.name) },
          },
          onError: (error) => {
            form.setFields([
              {
                name: 'name',
                errors: [error?.message],
              },
            ]);
          },
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const ModalTitle = () => (
    <div className="mb-16">
      <Title level={4} className="font-secondary text-content-primary">
        {title}
      </Title>
    </div>
  );

  return (
    <>
      <Modal
        title={<ModalTitle />}
        open={isVisible}
        onCancel={handleCancel}
        className="common-key-modal"
        width={MODAL_SIZE.large}
        footer={[
          <CommonButton key="cancel" onClick={handleCancel} disabled={loading}>
            Cancel
          </CommonButton>,
          <CommonButton
            key="confirm"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            Create
          </CommonButton>,
        ]}
      >
        <div className="mt-8">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleConfirm}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              rules={[formValidation.required, formValidation.whitespace]}
            >
              <Input
                placeholder="Enter key name"
                className="w-full"
                maxLength={FORM_CHARACTER_LIMIT.apiKeyNameLimit}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CreateApiKeyModal;
