import { ConfigProvider } from 'antd';
import { useState } from 'react';
import { Knowledge } from '../../../../../__generated__/graphql';
import AddKnowledge from './AddKnowledge';
import KnowledgeList from './KnowledgeList';

export const KnowledgeForm = () => {
  const [step, setStep] = useState(1);
  const [knowledgeId, setKnowledgeId] = useState<string>('');

  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBorder: 'var(--border-primary)',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <section>
          <div className="flex justify-center mx-auto flex-column">
            <div>
              {step === 2 ? (
                <AddKnowledge
                  knowledgeId={knowledgeId}
                  onBack={() => {
                    setStep(1);
                    setKnowledgeId('');
                  }}
                />
              ) : (
                <KnowledgeList
                  onBack={() => {
                    // setCounter(2);
                    // navigate(`${ROUTES.SHARE}/${formId}/${shareId}`);
                  }}
                  onClick={(item?: Knowledge) => {
                    setStep(2);
                    setKnowledgeId(item?.id || '');
                  }}
                />
              )}
            </div>
          </div>
        </section>
      </ConfigProvider>
    </section>
  );
};
