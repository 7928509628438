import { useQuery } from '@apollo/client';
import { Segmented } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  FormSubmissionStatus,
  ListFormSubmissionsSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { hasFeatureAccess } from '../../common/utils';
import PremiumLogo from '../../components/common/PremiumLogo';
import SubscribeModal from '../../components/common/SubscribeModal';
import useFeatureAccess from '../../hooks/useFeatureAccess';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FeatureName } from '../../types/common.type';
import { GET_RESULT_OVERVIEW } from '../form/graphql/queries';
import { FeatureKeys } from '../profile/profile.types';
import ByParticipants from './ByParticipants';
import ByQuestions from './ByQuestions';
import Overview from './Overview';
import PartialResults from './PartialResults';
import { FormResultBy } from './result.types';

export default function Results() {
  const [resultType, setResultType] = useState<FormResultBy>();
  const { navigate, params } = useRouter();
  const {
    hasPermission,
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const [menuItems, setMenuItems] = useState<
    {
      value: FormResultBy;
      label: string | React.ReactElement;
      icon?: React.ReactElement | null;
      disabled?: boolean;
    }[]
  >();
  const formId = params?.id as string;
  const type = params?.type as FormResultBy;

  const hasPartialAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.SHOW_PARTIAL_FORM_SUBMISSIONS,
  );

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
  } = useFeatureAccess();

  const [initialFilter] = useState({
    filter: {
      isTest: false,
      status: FormSubmissionStatus.Completed,
      limit: 10,
      skip: 0,
    },
    where: {
      formId,
    },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: ListFormSubmissionsSortOnField.CreatedAt,
      },
    ],
  });

  const { data } = useQuery(GET_RESULT_OVERVIEW, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        formId,
      },
      filter: {
        isTest: false,
      },
    },
  });

  useEffect(() => {
    setResultType(type || FormResultBy.Overview);
  }, [type]);

  useEffect(() => {
    setMenuItems([
      {
        value: FormResultBy.Overview,
        label: 'Overview',
      },
      {
        value: FormResultBy.ByQuestion,
        label: 'By Questions',
      },
      {
        value: FormResultBy.ByParticipants,
        label: `By Participants (${data?.formSubmissionOverview?.data?.completedSubmissionCount || 0})`,
      },
      {
        value: FormResultBy.PartiallyFilled,
        label: `Partially filled (${data?.formSubmissionOverview?.data?.abandonedSubmissionCount || 0})`,
        icon: !hasPartialAccess ? (
          <PremiumLogo featureName={FeatureName.PartialForm} />
        ) : null,
      },
    ]);
  }, [hasPartialAccess, currentUser, data]);

  const renderSegmentedContent = () => {
    switch (resultType) {
      case FormResultBy.Overview:
        return <Overview />;
      case FormResultBy.ByQuestion:
        return <ByQuestions />;
      case FormResultBy.ByParticipants:
        return <ByParticipants />;
      case FormResultBy.PartiallyFilled:
        return <PartialResults />;
      default:
        setResultType(FormResultBy.Overview);
        return <Overview />;
    }
  };

  const handleChange = (type: FormResultBy) => {
    if (type === FormResultBy.PartiallyFilled) {
      checkFeatureAccess(FeatureKeys.SHOW_PARTIAL_FORM_SUBMISSIONS, () => {
        navigate(`${ROUTES.RESULTS}/${type}/${formId}`);
      });
    } else {
      navigate(`${ROUTES.RESULTS}/${type}/${formId}`);
    }
  };

  return (
    <section className="editor-wrapper result-page">
      <div className="result-segments-wrapper mb-16">
        <Segmented
          className="app-segments"
          value={resultType}
          options={menuItems || []}
          onChange={handleChange}
        />
      </div>
      <div className="segment-content">{renderSegmentedContent()}</div>
      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
          featureName={FeatureName.PartialForm}
        />
      )}
    </section>
  );
}
