import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Spin,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DEFAULT_AUTO_SAVE_TIME, TOAST_MESSAGE } from '../../common/constants';
import {
  formItemProps,
  formValidation,
  hasFeatureAccess,
} from '../../common/utils';
import ActivityLogModal from '../../components/common/ActivityLogModal';
import PremiumLogo from '../../components/common/PremiumLogo';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FeatureName, Permissions } from '../../types/common.type';
import FormAlertMessage from '../form/editor/utils/FormAlertMessage';
import InfoLabel from '../form/editor/utils/InfoLabel';
import { FeatureKeys } from '../profile/profile.types';
import { TEST_WEBHOOK, UPSERT_WEBHOOK } from './graphql/mutations';
import { GET_INTEGRATION_CONFIGS } from './graphql/queries';

export default function WebhookInfo() {
  let isValidating = false; // Flag to prevent re-entrance

  const [isActivityModal, setIsActivityModal] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const {
    state: { currentUser },
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const { params } = useRouter();
  const formId = params?.id;
  const [webhookForm] = useForm();

  const [upsertIntegrationMutate] = useMutation(UPSERT_WEBHOOK, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [urlFieldStatus, setUrlFieldStatus] = useState<{
    status?: 'error' | 'success';
    message?: string;
  }>({});

  const [testWebhookMutate, { loading: updateLoading }] = useMutation(
    TEST_WEBHOOK,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        setUrlFieldStatus({
          status: 'error',
          message: err?.message,
        });
      },
      onCompleted: (res) => {
        setUrlFieldStatus({
          status: 'success',
          message:
            res.testWebhook?.message || TOAST_MESSAGE.webhookTastedSuccess,
        });
      },
    },
  );

  const [fetchIntegrationConfigs, { loading }] = useLazyQuery(
    GET_INTEGRATION_CONFIGS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data =
          res.integrationConfigs?.data?.integrationConfigs?.[0]?.configs;
        webhookForm.setFields([
          {
            name: 'url',
            value: data?.url,
          },
          {
            name: 'secret',
            value: data?.secret,
          },
        ]);
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    if (formId) {
      fetchIntegrationConfigs({
        variables: {
          where: {
            formId,
          },
        },
      });
    }
  }, [formId, webhookForm]);

  const handleFieldChanges = () => {
    // prevent re-entrance if already validating
    if (isValidating) return;

    // set the flag to true before validation
    isValidating = true;

    webhookForm
      .validateFields()
      .then((values) => {
        upsertIntegrationMutate({
          variables: {
            data: values,
            where: {
              formId: formId!,
            },
          },
        });
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => {
        // reset the flag after validation is complete
        isValidating = false;
      });
  };

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      handleFieldChanges();
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  const handleTestButton = () => {
    const urlFieldValue = webhookForm.getFieldValue('url');
    if (formId && urlFieldValue) {
      testWebhookMutate({
        variables: {
          where: {
            formId,
          },
        },
      });
    } else {
      webhookForm.setFields([
        {
          name: 'url',
          errors: [TOAST_MESSAGE.addWebhookUrl],
        },
      ]);
    }
  };

  const allowWebhook = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.WEBHOOK_SUPPORT,
  );

  const editAccess = hasPermission(Permissions.WRITE);

  return (
    <Spin spinning={loading}>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Divider className="section-divider" dashed />
        <Flex
          className="form-settings-wrapper mb-32 scroll-wrapper-body"
          gap={20}
          wrap
          vertical
        >
          <Flex gap={8} justify="space-between" align="flex-start">
            <Flex vertical gap={2}>
              <Flex gap={8} align="center">
                <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                  Webhook
                </Paragraph>
                {!allowWebhook && (
                  <PremiumLogo
                    featureKey={FeatureKeys.WEBHOOK_SUPPORT}
                    featureName={FeatureName.WebhookSupport}
                    isSubscriptionModal
                  />
                )}
              </Flex>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Add webhooks to automate real-time data updates.
              </Paragraph>
            </Flex>
          </Flex>
          <Form
            form={webhookForm}
            autoComplete="off"
            onFinish={handleTestButton}
            className="webhook-info-form"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label={<InfoLabel title="URL" />}
                  labelCol={{ span: 24 }}
                  name="url"
                  rules={[formValidation?.url]}
                  normalize={formItemProps.normalize}
                  validateStatus={urlFieldStatus.status}
                  help={
                    urlFieldStatus.message ? (
                      <FormAlertMessage
                        message={urlFieldStatus.message}
                        status={urlFieldStatus.status}
                      />
                    ) : null
                  }
                >
                  <Input
                    placeholder="Enter URL"
                    className="w-full"
                    onChange={() => {
                      setUrlFieldStatus({});
                      handleAutoSave();
                    }}
                    disabled={!allowWebhook || !editAccess}
                  />
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label={<InfoLabel title="Secret" />}
                  labelCol={{ span: 24 }}
                  name="secret"
                  normalize={formItemProps.normalize}
                >
                  <Input
                    placeholder="Enter secret"
                    className="w-full"
                    onChange={handleAutoSave}
                    disabled={!allowWebhook || !editAccess}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Flex gap={12}>
            <CommonButton
              type="text"
              disabled={!allowWebhook}
              loading={updateLoading}
              onClick={() => {
                webhookForm.submit();
              }}
            >
              Test
            </CommonButton>
            <CommonButton
              type="text"
              onClick={() => {
                setIsActivityModal(true);
              }}
              disabled={!allowWebhook || updateLoading}
            >
              Activity Log
            </CommonButton>
          </Flex>
        </Flex>
      </ConfigProvider>
      {isActivityModal && (
        <ActivityLogModal
          isVisible={isActivityModal}
          onCancel={() => setIsActivityModal(false)}
        />
      )}
    </Spin>
  );
}
