import { Button, Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { capitalize, lowerCase } from 'lodash';
import { STATIC_FORM_FIELDS } from '../../../../common/constants';
import {
  FormPageType,
  FormSchemaType,
  PageLayoutType,
  Palette,
} from '../../../form/form.types';

export default function PagePreview({
  item,
  activeTheme,
  handleCTAClick,
  loading,
  isMobile = false,
  pageType,
  allowEditResponse,
}: {
  item: FormSchemaType;
  activeTheme: Palette;
  loading?: boolean;
  handleCTAClick: () => void;
  isMobile?: boolean;
  pageType: FormPageType;
  allowEditResponse: boolean;
}) {
  const showEditResponseButton =
    allowEditResponse && pageType === FormPageType.END_PAGE;

  const renderPage = () => {
    const imgUrl = item?.bg_image_url;
    const btnTitle =
      item?.cta_name ||
      (pageType === FormPageType.WELCOME_PAGE
        ? STATIC_FORM_FIELDS.defaultWelcomePage?.[0]?.cta_name
        : '');

    switch (item.page_layout) {
      case PageLayoutType.PAGE_TOP_CENTER:
        return (
          <Flex
            className="page-top-center"
            vertical
            align="center"
            gap={32}
            justify="center"
          >
            {imgUrl ? (
              <img className="page-image" src={imgUrl} />
            ) : (
              <Paragraph className="mb-0 text-center">
                No Preview Image
              </Paragraph>
            )}
            <Flex gap={24} vertical className="right-section">
              <Title
                className="page-title"
                style={{ color: activeTheme?.textColor }}
              >
                {item?.title}
              </Title>
              <Paragraph
                className="page-body mb-0"
                style={{ color: activeTheme?.textColor }}
              >
                {item?.body}
              </Paragraph>
            </Flex>
            <Flex gap={16} align="center" wrap="wrap-reverse" justify="center">
              {showEditResponseButton && (
                <Button
                  type="default"
                  size="large"
                  shape="round"
                  className={`${isMobile ? 'text-sm' : ''}`}
                  onClick={() => handleCTAClick()}
                  loading={loading}
                >
                  Edit response
                </Button>
              )}
              {btnTitle && (
                <Button
                  type="primary"
                  size="large"
                  className={`${isMobile ? 'text-sm' : ''}`}
                  shape="round"
                  onClick={() => handleCTAClick()}
                  loading={loading}
                >
                  {btnTitle}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      case PageLayoutType.PAGE_FILLED:
        return (
          <Flex className="page-top-center" vertical align="center" gap={32}>
            <Flex gap={24} vertical align="center">
              <Title
                className="page-title"
                style={{ color: activeTheme?.textColor }}
              >
                {item?.title}
              </Title>
              <Paragraph
                className="page-body mb-0"
                style={{ color: activeTheme?.textColor }}
              >
                {item?.body}
              </Paragraph>
            </Flex>
            <Flex gap={16} align="center" wrap="wrap-reverse" justify="center">
              {showEditResponseButton && (
                <Button
                  type="default"
                  size="large"
                  shape="round"
                  className={`${isMobile ? 'text-sm' : ''}`}
                  onClick={() => handleCTAClick()}
                  loading={loading}
                >
                  Edit response
                </Button>
              )}
              {btnTitle && (
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  className={`${isMobile ? 'text-sm' : ''}`}
                  onClick={() => handleCTAClick()}
                  loading={loading}
                >
                  {btnTitle}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      case PageLayoutType.PAGE_LEFT:
        return (
          <div className="page-left">
            <div className="image-wrapper">
              {imgUrl ? (
                <img className="page-image" src={imgUrl} />
              ) : (
                <Paragraph className="mb-0 text-center">
                  No Preview Image
                </Paragraph>
              )}
            </div>
            <div>
              <Flex
                gap={32}
                vertical
                justify="center"
                align={isMobile ? 'center' : 'flex-start'}
                className="right-section"
              >
                <Flex gap={24} vertical>
                  <Title
                    className="page-title"
                    style={{ color: activeTheme?.textColor }}
                  >
                    {item?.title}
                  </Title>
                  <Paragraph
                    className="page-body mb-0"
                    style={{ color: activeTheme?.textColor }}
                  >
                    {item?.body}
                  </Paragraph>
                </Flex>
                <Flex
                  gap={16}
                  align="center"
                  wrap="wrap-reverse"
                  justify="center"
                >
                  {showEditResponseButton && (
                    <Button
                      type="default"
                      size="large"
                      shape="round"
                      className={`${isMobile ? 'text-sm' : ''}`}
                      onClick={() => handleCTAClick()}
                      loading={loading}
                    >
                      Edit response
                    </Button>
                  )}
                  {btnTitle && (
                    <Button
                      type="primary"
                      size="large"
                      className={`${isMobile ? 'text-sm' : ''}`}
                      shape="round"
                      onClick={() => handleCTAClick()}
                      loading={loading}
                    >
                      {btnTitle}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className={`welcome-page-wrapper ${isMobile ? 'mobile' : ''}`}>
      {item?.is_display ? (
        renderPage()
      ) : (
        <Paragraph className="mb-0 text-m medium text-center">
          {`${capitalize(lowerCase(pageType))} is turned off, enable it to preview
          here.`}
        </Paragraph>
      )}
    </div>
  );
}
