import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CheckCircle,
  Note,
  PhoneList,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import { Empty, Flex, Popconfirm, Spin, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, map } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Contact,
  FormTask,
  ListFormTaskSortOnField,
  SortOrder,
} from '../../../../../__generated__/graphql';
import { AppContext } from '../../../../../AppContext';
import {
  DEFAULT_ITEMS_PER_PAGE,
  initialPaginationFilter,
} from '../../../../../common/constants';
import {
  handleGraphQlError,
  handleGraphQlSuccess,
  hasFeatureAccess,
  toTitleCase,
} from '../../../../../common/utils';
import SubscribeModal from '../../../../../components/common/SubscribeModal';
import CommonButton from '../../../../../components/primitives/CommonButton';
import useFeatureAccess from '../../../../../hooks/useFeatureAccess';
import useRouter from '../../../../../hooks/useRouter';
import { AppContextType } from '../../../../../types/appContext.type';
import { FeatureName, Permissions } from '../../../../../types/common.type';
import { FeatureKeys } from '../../../../profile/profile.types';
import {
  CREATE_FORM_SHARE,
  DELETE_FORM_TASK,
} from '../../../graphql/mutations';
import { GET_FORM_TASK } from '../../../graphql/queries';
import CommonDeleteMessage from '../../utils/CommonDeleteMessage';
import { CommonContactTypes } from '../pages.types';

export default function ContactList({ onClick }: CommonContactTypes) {
  const { params } = useRouter();
  const formId = params?.id as string;
  const [dataList, setDataList] = useState<Contact[]>([]);
  const {
    state: { formSettings, currentUser },
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);

  const initialSort = {
    sortBy: SortOrder.Desc,
    sortOn: ListFormTaskSortOnField.CreatedAt,
  };

  const [getFormKnowledges, { loading }] = useLazyQuery(GET_FORM_TASK, {
    variables: {
      filter: { ...initialPaginationFilter, formId },
      sort: initialSort,
    },
    onCompleted: (res) => {
      setDataList(res.formTasks?.data?.formTasks as Contact[]);
      setHasMore(
        Number(res.formTasks?.data?.formTasks) > DEFAULT_ITEMS_PER_PAGE,
      );
      setCount(res.formTasks?.count || 0);
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    isUpgradeSuccessfulModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
    closeUpgradeSuccessfulModal,
  } = useFeatureAccess();

  const [createFormShare, { loading: createLoading }] = useMutation(
    CREATE_FORM_SHARE,
    {
      onError: () => {},
    },
  );

  const editAccess = hasPermission(Permissions.WRITE);

  useEffect(() => {
    if (formId) {
      getFormKnowledges();
    }
  }, [formId]);

  const DataCard = ({
    item,
    hideButtons,
  }: {
    item: FormTask;
    hideButtons: boolean;
  }) => {
    const [deleteFormTask, { loading: deleteLoading }] = useMutation(
      DELETE_FORM_TASK,
      {
        onError: (err) => {
          handleGraphQlError(err.message);
        },
      },
    );

    const handleDelete = (id: string) => {
      deleteFormTask({
        variables: {
          where: {
            formTaskId: id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.deleteFormTask?.message);
          setDataList(dataList?.filter((item) => item?.id !== id));
        },
      });
    };

    return (
      <Flex className="task-card" justify="space-between" align="center">
        <Flex align="center" gap={12}>
          <CommonButton
            shape="circle"
            size="small"
            icon={<Note size={18} color="var(--content-primary)" />}
          ></CommonButton>
          <Flex vertical gap={4}>
            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
              {`${item.contact?.firstName} ${item.contact?.lastName}`}
            </Paragraph>
            <Paragraph className="mb-0">{item.contact?.phoneNumber}</Paragraph>
            {item.channel && (
              <Paragraph className="mb-0">
                {toTitleCase(item.channel)}
              </Paragraph>
            )}
            {item.submission?.status && (
              <Paragraph className="mb-0">
                {toTitleCase(item.submission?.status)}
              </Paragraph>
            )}
            {item?.metadata?.inbound && (
              <Paragraph className="mb-0">
                {item?.metadata?.inbound ? (
                  <Flex align="center" gap={4}>
                    <CheckCircle />
                    <Paragraph className="mb-0">Inbound</Paragraph>
                  </Flex>
                ) : null}
              </Paragraph>
            )}
          </Flex>
        </Flex>
        <Flex gap={12} align="center">
          {!hideButtons && editAccess && (
            <Tooltip title="Delete">
              <Popconfirm
                title={
                  <CommonDeleteMessage message="This will delete the knowledge." />
                }
                okText="Yes"
                className="hover-show"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  handleDelete(item.id!);
                }}
                onCancel={(e) => e?.stopPropagation()}
                placement="right"
                okButtonProps={{
                  block: true,
                  icon: <DeleteOutlined />,
                  danger: true,
                }}
                cancelButtonProps={{ block: true }}
                icon={null}
              >
                <CommonButton
                  size="small"
                  type="text"
                  shape="circle"
                  className="bg-surface-tertiary"
                  shadow={false}
                  loading={deleteLoading}
                  onClick={(e) => e.stopPropagation()}
                  icon={<Trash color="var(--danger-on-surface)" size={16} />}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );
  };

  const addKnowledgeList = [
    {
      key: 'CONTACT',
      label: 'Contacts',
      icon: <PhoneList size={16} />,
    },
  ];

  const handleCreate = () => {
    onClick && onClick();
  };

  // load more func when last element becomes visible
  const loadMore = () => {
    getFormKnowledges({
      variables: {
        filter: {
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: dataList?.length,
          formId: formId,
        },
        sort: initialSort,
      },
      onCompleted: (res) => {
        const mergedData = [
          ...dataList,
          ...(res.formTasks?.data?.formTasks as Contact[]),
        ];
        setDataList(mergedData);
        setHasMore(Number(res.formTasks?.data?.formTasks) > mergedData.length);
        setCount(res.formTasks?.count || 0);
      },
    });
  };

  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const hasShareAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.ALPHA_FEATURE,
  );

  return (
    <>
      <Flex className="" vertical>
        <Flex gap={24} className="scroll-wrapper-header" vertical>
          <Flex className="w-full" justify="space-between" align="center">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                Form Contacts
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Add a contact to setup contact settings.
              </Paragraph>
            </Flex>
            {/* <Dropdown
              disabled={createLoading || !editAccess}
              menu={{
                items: addKnowledgeList,
                onClick: ({ key }) => {
                  checkFeatureAccess(FeatureKeys.ALPHA_FEATURE, () => {
                    handleCreate();
                  });
                },
              }}
              placement="bottom"
              trigger={['click']}
            > */}
            <CommonButton
              type="text"
              icon={<Plus size={14} />}
              premium={!hasShareAccess}
              loading={createLoading}
              disabled={!editAccess || createLoading}
              onClick={() => {
                checkFeatureAccess(FeatureKeys.ALPHA_FEATURE, () => {
                  handleCreate();
                });
              }}
            >
              Add
            </CommonButton>
            {/* </Dropdown> */}
          </Flex>
        </Flex>
        <div className="scroll-wrapper-body">
          {/* Loading state */}
          <Spin spinning={loading}>
            <Flex vertical gap={16}>
              {map(dataList, (item: FormTask, idx: number) => {
                return (
                  <div ref={idx === dataList?.length - 1 ? lastItemRef : null}>
                    <DataCard item={item} hideButtons={!hasShareAccess} />
                  </div>
                );
              })}
              {loading && <Spin spinning />}
            </Flex>
          </Spin>

          {/* Empty state */}
          {isEmpty(dataList) && !loading && (
            <Empty description="No contacts found" />
          )}
        </div>
      </Flex>

      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
          featureName={FeatureName.MultiFormLink}
        />
      )}
      {/* {isUpgradeSuccessfulModalVisible && (
        <UpgradeSuccessful
          isVisible={isUpgradeSuccessfulModalVisible}
          onClose={closeUpgradeSuccessfulModal}
        />
      )} */}
    </>
  );
}
