import { useLazyQuery } from '@apollo/client';
import { CaretLeft, CaretRight, X } from '@phosphor-icons/react';
import { Flex, Modal, Table } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { lowerCase } from 'lodash';
import { useEffect, useState } from 'react';
import {
  ListWebhookLogsOnField,
  SortOrder,
  WorkflowRun,
  WorkflowRunStatus,
  WorkflowRunSteps,
} from '../../__generated__/graphql';
import {
  DEFAULT_DATE_WITH_TIME_FORMAT,
  DEFAULT_ITEMS_PER_PAGE,
  initialPaginationFilter,
  MODAL_SIZE,
} from '../../common/constants';
import { handleGraphQlError, renderWebhookStatus } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import {
  GET_WEBHOOK_LOG_DETAILS,
  GET_WEBHOOK_LOGS,
} from '../../modules/webhook/graphql/queries';
import WebhookDetails from '../../modules/webhook/WebhookDetails';
import CommonButton from '../primitives/CommonButton';
dayjs.extend(relativeTime);

type ActivityLogModalProps = {
  isVisible: boolean;
  onCancel: () => void;
};

const ActivityLogModal: React.FC<ActivityLogModalProps> = ({
  isVisible,
  onCancel,
}) => {
  const [step, setStep] = useState(0);
  const [currentAttemptIndex, setCurrentAttemptIndex] = useState<number>(1);
  const [details, setDetails] = useState<WorkflowRunSteps>();
  const [webhookDetails, setWebhookDetails] = useState<WorkflowRun>();
  const [hasMore, setHasMore] = useState(true);
  const { params, navigate } = useRouter();
  const formId = params?.id;
  const [items, setItems] = useState<WorkflowRun[]>([]);

  const defaultSorting = [
    {
      sortBy: SortOrder.Desc,
      sortOn: ListWebhookLogsOnField.CreatedAt,
    },
  ];

  const [fetchWebhookLogs, { loading }] = useLazyQuery(GET_WEBHOOK_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setItems(res.webhookLogs?.data as WorkflowRun[]);
      setHasMore(Number(res.webhookLogs?.count) > DEFAULT_ITEMS_PER_PAGE);
    },
    onError: (err) => {
      handleGraphQlError(err.message);
    },
  });

  const [fetchWebhookDetails, { loading: detailsLoading }] = useLazyQuery(
    GET_WEBHOOK_LOG_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setDetails(
          (res.webhookLog?.data?.workflowRun?.steps?.[0] as WorkflowRunSteps) ||
            [],
        );
        setStep(1);
        setCurrentAttemptIndex(1);
      },
      onError: (err) => {
        handleGraphQlError(err.message);
      },
    },
  );

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    if (formId) {
      fetchWebhookLogs({
        variables: {
          filter: initialPaginationFilter,
          sort: defaultSorting,
          where: {
            formId,
          },
        },
      });
    }
  }, [formId]);

  const handleViewDetails = (id: string, record: WorkflowRun) => {
    setWebhookDetails(record);
    fetchWebhookDetails({
      variables: {
        where: {
          workflowRunId: id,
        },
      },
    });
  };

  const ModalTitle = () => (
    <Flex gap={8} align="center" justify="space-between">
      <Title level={4} className="text-content-primary">
        Activity log
      </Title>
      <X
        size={24}
        color="var(--content-tertiary)"
        className="cursor-pointer"
        onClick={onCancel}
      />
    </Flex>
  );

  const ActivityLogModalTitle = () => (
    <Flex gap={8} align="center" justify="space-between">
      <Flex align="center" gap={2}>
        <CommonButton
          shadow={false}
          shape="circle"
          icon={<CaretLeft size={24} color="var(--content-tertiary)" />}
          onClick={() => setStep(0)}
          size="small"
        />
        <Flex vertical gap={2}>
          <Paragraph className="text-content-primary text-sm mb-0 medium">
            {lowerCase(webhookDetails?.triggeredEvent || '-')}
          </Paragraph>
          <Paragraph className="mb-0 text-content-tertiary medium">
            {dayjs(webhookDetails?.createdAt).format(
              DEFAULT_DATE_WITH_TIME_FORMAT,
            )}
          </Paragraph>
        </Flex>
      </Flex>
      <Flex align="center" gap={24}>
        <Flex gap={16} align="center">
          <Paragraph className="mb-0 text-content-primary medium">
            {webhookDetails?.status
              ? renderWebhookStatus(webhookDetails?.status)
              : '-'}
          </Paragraph>
          <Flex align="center" className="button-wrapper" gap={8}>
            <CommonButton
              size="small"
              type="text"
              className="navigation-button left"
              shadow={false}
              icon={<CaretLeft size={24} color="var(--content-tertiary)" />}
              disabled={currentAttemptIndex === 1}
              onClick={() => {
                setCurrentAttemptIndex(currentAttemptIndex - 1);
              }}
            />
            <Paragraph className="mb-0 text-sm text-content-primary">
              Attempt {currentAttemptIndex} of {details?.attempts?.length}
            </Paragraph>
            <CommonButton
              size="small"
              type="text"
              className="navigation-button right"
              shadow={false}
              icon={<CaretRight size={24} color="var(--content-tertiary)" />}
              disabled={currentAttemptIndex === details?.attempts?.length}
              onClick={() => {
                setCurrentAttemptIndex(currentAttemptIndex + 1);
              }}
            />
          </Flex>
        </Flex>
        <X
          size={24}
          color="var(--content-tertiary)"
          className="cursor-pointer"
          onClick={onCancel}
        />
      </Flex>
    </Flex>
  );

  const columns = [
    {
      title: 'Event Name',
      dataIndex: 'triggeredEvent',
      key: 'triggeredEvent',
      render: (value: string) => (
        <Paragraph className="mb-0 text-sm medium text-content-primary">
          {lowerCase(value || '-')}
        </Paragraph>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: WorkflowRunStatus) =>
        value ? (
          renderWebhookStatus(value)
        ) : (
          <Paragraph className="mb-0 text-sm text-content-primary">
            {'-'}
          </Paragraph>
        ),
    },
    {
      title: 'Attempts',
      dataIndex: 'retryCount',
      key: 'retryCount',
      render: (value: string) => (
        <Paragraph className="mb-0 text-sm text-content-primary">
          {value ?? '-'}
        </Paragraph>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: Date) => (
        <Flex vertical align="flex-start">
          <Paragraph className="mb-0 text-sm text-content-primary">
            {dayjs(value).format(DEFAULT_DATE_WITH_TIME_FORMAT)}
          </Paragraph>
        </Flex>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (value: string, record: WorkflowRun) => {
    //     if (value) {
    //       return (
    //         <Link underline onClick={() => handleViewDetails(value, record)}>
    //           View Details
    //         </Link>
    //       );
    //     } else {
    //       <Paragraph className="mb-0 text-sm text-content-primary">
    //         {'-'}
    //       </Paragraph>;
    //     }
    //   },
    // },
  ];

  const loadMore = () => {
    if (formId) {
      fetchWebhookLogs({
        variables: {
          filter: {
            limit: DEFAULT_ITEMS_PER_PAGE,
            skip: items?.length,
          },
          sort: defaultSorting,
          where: {
            formId,
          },
        },
        onCompleted: (res) => {
          const mergedData = [
            ...items,
            ...(res.webhookLogs?.data as WorkflowRun[]),
          ];
          setItems(mergedData);
          setHasMore(Number(res.webhookLogs?.count) > mergedData.length);
        },
      });
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement;
    const isAtBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (isAtBottom && hasMore) {
      loadMore();
    }
  };

  return (
    <Modal
      title={step === 0 ? <ModalTitle /> : <ActivityLogModalTitle />}
      width={MODAL_SIZE.extraLarge}
      open={isVisible}
      onCancel={handleCancel}
      className={`activity-log-modal ${step === 1 ? 'log-details' : ''}`}
      footer={null}
      centered
      closeIcon={null}
      maskClosable={false}
    >
      {step === 0 ? (
        <Table
          className="activity-record-table"
          dataSource={items}
          columns={columns}
          loading={loading || detailsLoading}
          pagination={false}
          locale={{
            emptyText: 'No logs found',
          }}
          scroll={{ y: 400 }}
          onScroll={handleScroll}
          onRow={(record: WorkflowRun) => {
            return {
              onClick: (event) => {
                event.preventDefault();
                if (record?.id) {
                  handleViewDetails(record.id, record);
                }
              },
            };
          }}
        />
      ) : (
        details &&
        webhookDetails && (
          <WebhookDetails
            webhookDetails={webhookDetails}
            details={details}
            currentIndex={currentAttemptIndex}
          />
        )
      )}
    </Modal>
  );
};

export default ActivityLogModal;
