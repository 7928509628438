import { useContext, useState } from 'react';
import { SubscriptionPlan } from '../__generated__/graphql';
import { AppContext } from '../AppContext';
import { hasFeatureAccess } from '../common/utils';
import { FeatureKeys } from '../modules/profile/profile.types';
import { AppContextType } from '../types/appContext.type';

const useFeatureAccess = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    useState(false);
  const [isUpgradeSuccessfulModalVisible, setIsUpgradeSuccessfulModalVisible] =
    useState(false);

  const checkFeatureAccess = (
    featureKey: FeatureKeys,
    callback: () => void,
    providedList?: SubscriptionPlan['keyFeatures'],
  ) => {
    if (
      hasFeatureAccess(
        providedList || currentUser?.subscriptionPlan?.features,
        featureKey,
      )
    ) {
      callback();
    } else {
      setIsSubscriptionModalVisible(true);
    }
  };

  const handleUpgradeSuccess = () => {
    setIsSubscriptionModalVisible(false);
    setIsUpgradeSuccessfulModalVisible(true);
  };

  const closeSubscriptionModal = () => setIsSubscriptionModalVisible(false);
  const closeUpgradeSuccessfulModal = () =>
    setIsUpgradeSuccessfulModalVisible(false);

  return {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    isUpgradeSuccessfulModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
    closeUpgradeSuccessfulModal,
  };
};

export default useFeatureAccess;
