import { Star, UploadSimple } from '@phosphor-icons/react';
import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Rate,
  Select,
  Slider,
  TimePicker,
  Upload,
  UploadFile,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import { map } from 'lodash';
import { useState } from 'react';
import { getBase64 } from '../../../../common/utils';
import { FormFieldProps, FormFieldType, Palette } from '../../form.types';

export const FormFieldClassic: React.FC<FormFieldProps> = ({
  name,
  label,
  rules,
  apiErrors,
  type,
  inputProps = {},
  formItemProps = {},
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const currentTheme: Palette = inputProps?.activeTheme;

  const uploadButton = (
    <Button type="primary" shape="round" size="large" icon={<UploadSimple />}>
      Upload
    </Button>
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const renderInput = () => {
    switch (type) {
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.EMAIL:
        return <Input {...inputProps} />;
      case FormFieldType.LONG_TEXT:
        return (
          <div className="relative">
            <TextArea {...inputProps} />
            {inputProps?.suffix}
          </div>
        );
      case FormFieldType.TEXT_AREA:
        return <TextArea {...inputProps} />;
      case FormFieldType.NUMBER:
        return <InputNumber {...inputProps} />;
      case FormFieldType.NUMBER_TEXT:
        return <Input {...inputProps} />;

      case FormFieldType.DATE:
        return <DatePicker {...inputProps} />;
      case FormFieldType.TIME:
        return (
          <>
            <TimePicker format="hh:mm" {...inputProps} />
            <div />
          </>
        );
      case FormFieldType.DROPDOWN:
        return <Select {...inputProps} />;
      // case FormFieldType.PHONE_NUMBER:
      //   return (
      //     <Row className="w-full" gutter={[16, 16]}>
      //       <Col span={6}>
      //         <Select
      //           options={DROPDOWN_LIST.countryList}
      //           showSearch
      //           defaultValue="+91"
      //         />
      //       </Col>
      //       <Col span={18}>
      //         <InputNumber className="w-full" {...inputProps} />
      //       </Col>
      //     </Row>
      //   );
      case FormFieldType.SELECT:
        if (inputProps?.options?.length > 0) {
          return (
            <Flex
              className="conversational-item-multi-select"
              gap={16}
              align="flex-start"
              wrap
            >
              {inputProps?.options?.length > 0 &&
                map(inputProps?.options, (item, idx) => (
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: currentTheme?.textColor,
                      },
                      components: {
                        Button: {
                          borderRadius: 2,
                          colorPrimary: currentTheme?.textColor,
                          colorPrimaryHover: currentTheme?.textColor,
                          defaultColor: currentTheme?.textColor,
                          defaultBg: 'transparent',
                          defaultBorderColor: currentTheme?.textColor,
                          defaultHoverBg: currentTheme?.textColor,
                          defaultHoverColor: currentTheme?.bgPrimary,
                        },
                      },
                    }}
                  >
                    <Button
                      type={
                        selectedOptions?.includes(item) ? 'primary' : 'default'
                      }
                      shape="round"
                      onClick={() => {
                        setSelectedOptions([item]);
                        inputProps.onChange([item]);
                      }}
                    >
                      {item || `Option ${idx + 1}`}
                    </Button>
                  </ConfigProvider>
                ))}
            </Flex>
          );
        }
        return null;

      case FormFieldType.MULTI_SELECT:
        if (inputProps?.options?.length > 0) {
          return (
            <Flex
              className="conversational-item-multi-select"
              gap={16}
              align="center"
              wrap
            >
              {map(inputProps?.options, (item, idx) => (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: currentTheme?.textColor,
                    },
                    components: {
                      Button: {
                        borderRadius: 2,
                        colorPrimary: currentTheme?.textColor,
                        colorPrimaryHover: currentTheme?.textColor,
                        defaultColor: currentTheme?.textColor,
                        defaultBg: 'transparent',
                        defaultBorderColor: currentTheme?.textColor,
                        defaultHoverBg: currentTheme?.textColor,
                        defaultHoverColor: currentTheme?.bgPrimary,
                      },
                    },
                  }}
                >
                  <Button
                    type={
                      selectedOptions?.includes(item) ? 'primary' : 'default'
                    }
                    shape="round"
                    onClick={() => {
                      let items = [];
                      if (selectedOptions?.includes(item)) {
                        items = selectedOptions.filter((i) => i !== item);
                      } else {
                        items = [...selectedOptions, item];
                      }
                      setSelectedOptions(items);
                      inputProps.onChange(items);
                    }}
                  >
                    {item || `Option ${idx + 1}`}
                  </Button>
                </ConfigProvider>
              ))}
            </Flex>
          );
        }
        return null;
      case FormFieldType.FILE:
        return (
          <Flex gap={16} vertical align="flex-start">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: currentTheme?.textColor,
                },
                components: {
                  Button: {
                    borderRadius: 2,
                    colorPrimary: currentTheme?.textColor,
                    colorPrimaryHover: currentTheme?.textColor,
                    defaultColor: currentTheme?.textColor,
                    defaultBg: 'transparent',
                    defaultBorderColor: currentTheme?.textColor,
                    defaultHoverBg: currentTheme?.textColor,
                    defaultHoverColor: currentTheme?.bgPrimary,
                  },
                  Upload: {
                    actionsColor: currentTheme?.textColor,
                  },
                },
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                onPreview={handlePreview}
                {...inputProps}
              >
                {inputProps?.fileList?.length >= Number(inputProps?.maxCount)
                  ? null
                  : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(''),
                    toolbarRender: () => null,
                  }}
                  src={previewImage}
                />
              )}
              {inputProps?.children}
            </ConfigProvider>
          </Flex>
        );
      case FormFieldType.RATE:
        return (
          <Rate
            character={(props) => {
              const { index = 0, value = 0 } = props;
              const isSelected = index + 1 <= value;
              return (
                <Star
                  color={
                    isSelected
                      ? currentTheme?.textColor
                      : currentTheme?.textColor
                  }
                  weight="fill"
                />
              );
            }}
            {...inputProps}
          />
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <Flex
            gap={16}
            className="w-full pl-2 pr-8 pl-8"
            align="flex-start"
            justify="space-between"
          >
            {inputProps?.minLabel && (
              <Paragraph className="text-right mb-0">
                {inputProps?.minLabel}
              </Paragraph>
            )}
            <Slider {...inputProps} />
            {inputProps?.maxLabel && (
              <Paragraph className="mb-0">{inputProps?.maxLabel}</Paragraph>
            )}
          </Flex>
        );
      default:
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        label={
          formItemProps?.required ? (
            <span>
              {label}
              <span
                style={{
                  color: currentTheme?.requiredColor ?? currentTheme?.textColor,
                }}
              >
                *
              </span>
            </span>
          ) : (
            label
          )
        }
        rules={rules}
        validateStatus={apiErrors?.[name as string] ? 'error' : undefined}
        help={apiErrors?.[name as string]}
        labelCol={{
          span: 24,
        }}
        {...formItemProps}
        required={false}
      >
        {renderInput()}
      </Form.Item>
      {inputProps?.footerButtons}
    </>
  );
};
