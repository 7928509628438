import { CopySimple } from '@phosphor-icons/react';
import { Divider, Flex, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, omit } from 'lodash';
import { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  vs,
  vscDarkPlus,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import untruncateJson from 'untruncate-json';
import {
  WorkflowRun,
  WorkflowRunAttempts,
  WorkflowRunSteps,
} from '../../__generated__/graphql';
import { copyToClipboard } from '../../common/utils';

type WebhookDetailsType = {
  webhookDetails: WorkflowRun;
  currentIndex: number;
  details: WorkflowRunSteps;
};

export default function WebhookDetails({
  webhookDetails,
  currentIndex,
  details,
}: WebhookDetailsType) {
  const [currentAttempt, setCurrentAttempt] =
    useState<WorkflowRunAttempts | null>();

  useEffect(() => {
    if (details?.attempts && !isEmpty(details?.attempts)) {
      setCurrentAttempt(details?.attempts?.[currentIndex - 1]);
    }
  }, [details, currentIndex]);

  const renderResponse = () => {
    const renderSyntaxHighlighter = (content: string) => (
      <div className="editor-block">
        <Flex align="center" justify="space-between">
          <Paragraph className="text-sm mb-0 text-content-primary">
            Response
          </Paragraph>
          <Tooltip title="Copy">
            <CopySimple
              size={20}
              color="var(--system-blue-dark)"
              className="cursor-pointer"
              onClick={() => copyToClipboard(content)}
            />
          </Tooltip>
        </Flex>
        <Divider className="code-divider" />
        <SyntaxHighlighter
          language="json"
          style={vs}
          wrapLines
          customStyle={{
            background: 'transparent',
            paddingTop: 0,
            margin: 0,
            fontSize: '14px',
            border: 0,
          }}
        >
          {content}
        </SyntaxHighlighter>
      </div>
    );
    if (!isEmpty(currentAttempt?.error)) {
      try {
        const parsedResponse = JSON.parse(
          untruncateJson(currentAttempt?.error?.message),
        );
        return renderSyntaxHighlighter(
          JSON.stringify(omit(parsedResponse, 'requestBody'), null, 2),
        );
      } catch (error) {
        return renderSyntaxHighlighter(
          JSON.stringify(
            currentAttempt?.error?.message || 'Invalid JSON Response',
            null,
            2,
          ),
        );
      }
    } else {
      try {
        const parsedResponse = JSON.parse(
          untruncateJson(details?.output || ''),
        );
        return renderSyntaxHighlighter(
          JSON.stringify(omit(parsedResponse, 'requestBody'), null, 2),
        );
      } catch (err) {
        return renderSyntaxHighlighter(
          JSON.stringify('Invalid JSON Response', null, 2),
        );
      }
    }
  };

  const renderRequest = () => {
    const renderSyntaxHighlighter = (content: string) => (
      <div className="editor-block dark">
        <Flex align="center" justify="space-between">
          <Paragraph className="text-sm mb-0 text-content-inverse-primary">
            Request
          </Paragraph>
          <Tooltip title="Copy">
            <CopySimple
              size={20}
              color="var(--system-blue-dark)"
              className="cursor-pointer"
              onClick={() => copyToClipboard(content)}
            />
          </Tooltip>
        </Flex>
        <Divider className="code-divider" />
        <SyntaxHighlighter
          language="json"
          style={vscDarkPlus}
          wrapLines
          customStyle={{
            background: 'transparent',
            paddingTop: 0,
            margin: 0,
            fontSize: '14px',
            border: 0,
          }}
        >
          {content}
        </SyntaxHighlighter>
      </div>
    );
    if (!isEmpty(currentAttempt?.error)) {
      try {
        const parsedResponse = JSON.parse(
          untruncateJson(currentAttempt?.error?.message),
        );
        return renderSyntaxHighlighter(
          JSON.stringify(parsedResponse?.requestBody, null, 2),
        );
      } catch (error) {
        return renderSyntaxHighlighter(
          JSON.stringify(
            currentAttempt?.error?.message || 'Invalid JSON Response',
            null,
            2,
          ),
        );
      }
    } else {
      try {
        const parsedResponse = JSON.parse(
          untruncateJson(details?.output || ''),
        );
        return renderSyntaxHighlighter(
          JSON.stringify(parsedResponse?.requestBody, null, 2),
        );
      } catch (err) {
        return renderSyntaxHighlighter(
          JSON.stringify('Invalid JSON Response', null, 2),
        );
      }
    }
  };

  return (
    <div className="webhook-details-wrapper">
      {/* commenting for future use */}
      {/* <div className="webhook-summary">
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Flex vertical gap={8}>
              <Paragraph className="mb-0 text-content-tertiary">
                Event Name
              </Paragraph>
              <Paragraph className="mb-0 text-content-primary medium">
                {lowerCase(webhookDetails?.triggeredEvent || '-')}
              </Paragraph>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={8}>
              <Paragraph className="mb-0 text-content-tertiary">
                Status
              </Paragraph>
              <Paragraph className="mb-0 text-content-primary medium">
                {webhookDetails?.status
                  ? renderWebhookStatus(webhookDetails?.status)
                  : '-'}
              </Paragraph>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={8}>
              <Paragraph className="mb-0 text-content-tertiary">
                Attempts
              </Paragraph>
              <Paragraph className="mb-0 text-content-primary medium">
                {webhookDetails?.retryCount ?? '-'}
              </Paragraph>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={8}>
              <Paragraph className="mb-0 text-content-tertiary">
                Created
              </Paragraph>
              <Paragraph className="mb-0 text-content-primary medium">
                {dayjs(webhookDetails?.createdAt).format(
                  DEFAULT_DATE_WITH_TIME_FORMAT,
                )}
              </Paragraph>
            </Flex>
          </Col>
        </Row>
      </div> */}

      {renderRequest()}
      {renderResponse()}
    </div>
  );
}
