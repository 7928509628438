import { gql } from '../../../__generated__';

export const GET_INTEGRATION_CONFIGS = gql(`
 query IntegrationConfigs($where: FormUniqueInput!) {
  integrationConfigs(where: $where) {
    count
    data {
      integrationConfigs {
        id
        configs
      }
    }
  }
}`);

export const GET_WEBHOOK_LOGS = gql(`
  query WebhookLogs($where: FormUniqueInput!, $filter: WebhookLogFilter, $sort: [ListWebhookLogs!]) {
  webhookLogs(where: $where, filter: $filter, sort: $sort) {
    count
    data {
      id
      status
      retryCount
      createdAt
      updatedAt
      triggeredEvent
      workflowInstanceId
      workflowTrigger {
        eventTypes
      }
    }
  }
}`);

export const GET_WEBHOOK_LOG_DETAILS = gql(`
  query WebhookLog($where: WorkflowRunUniqueInput!) {
  webhookLog(where: $where) {
    data {
      workflowRun {
        steps {
          attempts {
            success
            error
          }
          output
        }
      }
    }
  }
}`);
