import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { Alert } from 'antd';

export default function FormAlertMessage({
  status,
  message,
}: {
  status?: 'error' | 'success';
  message?: string;
}) {
  return (
    <Alert
      className="form-alert-message small mt-8 w-full"
      message={message}
      type={status}
      showIcon
      icon={
        status === 'success' ? (
          <CheckCircle weight="fill" color="var(--success-on-surface)" />
        ) : (
          <XCircle weight="fill" color="var(--danger-on-surface)" />
        )
      }
    ></Alert>
  );
}
