'use Client';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Check } from '@phosphor-icons/react';
import { Modal, Skeleton } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { lowerCase, map } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  SubscriptionPlan,
  SubscriptionPlanLabel,
  SubscriptionPlanType,
  WorkspacesResponse,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import subscribeBackground from '../../assets/images/subscribe-modal-bg.png';
import {
  DEFAULT_PLAN_SORT,
  initialWorkspaceListFilter,
  MODAL_SIZE,
  ROUTES,
} from '../../common/constants';
import { currencyCodeToSymbol } from '../../common/utils';
import { PaddleCheckout } from '../../modules/payment/PaddleCheckout';
import { GET_PLAN_LIST } from '../../modules/profile/graphql/queries';
import { PlanType } from '../../modules/profile/profile.types';
import { GET_USERS_WORKSPACES } from '../../modules/workspace/graphql/queries';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { FeatureName, Permissions } from '../../types/common.type';
import PremiumLogo from './PremiumLogo';

type SubscribeModalProps = {
  visible: boolean;
  onCancel: () => void;
  onUpgrade?: () => void;
  featureName?: FeatureName;
};

const SubscribeModal: React.FC<SubscribeModalProps> = ({
  visible,
  onCancel,
  onUpgrade,
  featureName = 'This feature',
}) => {
  const { loading, refetch, data } = useQuery(GET_PLAN_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      sort: DEFAULT_PLAN_SORT,
      filter: {
        label: SubscriptionPlanLabel.Premium,
      },
    },
  });
  const { dispatch, getWorkspaceId, hasPermission, updateCurrentUser } =
    useContext(AppContext) as AppContextType;

  const [fetchWorkspaces] = useLazyQuery(GET_USERS_WORKSPACES, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.userWorkspaces,
        data: res.workspaces as WorkspacesResponse,
      });
    },
  });

  const FeatureListItem = ({ list }: { list: string[] }) => {
    return (
      <ul className="mt-16">
        {map(list, (text) => {
          return (
            <li>
              <Check color="var(--system-green-dark)" size={20} weight="bold" />
              {text}
            </li>
          );
        })}
        <Link
          to={`${ROUTES.PLAN_BILLING}/${getWorkspaceId()}`}
          className="w-max-content"
        >
          <li className="text-content-inverse-primary text-underline">
            <svg height={16} width={16}></svg>& More...
          </li>
        </Link>
      </ul>
    );
  };

  const handlePaymentSuccess = async () => {
    refetch();
    const uuid = getWorkspaceId()!;
    updateCurrentUser(uuid, async () => {
      await fetchWorkspaces({
        variables: initialWorkspaceListFilter,
      });
      if (onUpgrade) {
        onUpgrade();
      }
    });
  };

  const handleSubscriptionCancel = async () => {
    refetch();
    const uuid = getWorkspaceId()!;
    updateCurrentUser(uuid, async () => {
      await fetchWorkspaces({
        variables: initialWorkspaceListFilter,
      });
      onCancel();
    });
  };

  const ownerAccess = hasPermission(Permissions.OWNER);

  return (
    <Modal
      width={MODAL_SIZE.large}
      className="subscribe-modal"
      open={visible}
      footer={false}
      onCancel={onCancel}
    >
      <img src={subscribeBackground} className="cover-img" alt="" />
      <div className="subscribe-modal-wrapper">
        <Paragraph className="text-lg font-secondary text-content-inverse-primary normal text-center">
          <span className="text-lg semi-bold">{featureName}</span> is only
          available in premium plan
        </Paragraph>
        <div className="subscribe-plan-card-wrapper">
          {map(
            data?.subscriptionPlanKeyFeatures?.data,
            (item: SubscriptionPlan) => {
              const status = item.workspaceSubscriptionStatus;
              return (
                <div className="subscribe-plan-card">
                  {lowerCase(item.label!) !== PlanType.FREE ? (
                    <div className="tag premium mb-8">
                      PREMIUM
                      <PremiumLogo />
                    </div>
                  ) : (
                    <div className="tag mb-8">{item.label}</div>
                  )}

                  <Paragraph className="font-secondary plan-label mb-0">
                    {lowerCase(item.label!) !== PlanType.FREE &&
                      item.type == SubscriptionPlanType.Monthly &&
                      `${currencyCodeToSymbol(item.currency || '')}${item.price || 0}/month`}
                  </Paragraph>
                  {/* <p className="text-sm mt-16">{item.description}</p> */}
                  {item.keyFeatures && (
                    <FeatureListItem list={item.keyFeatures as string[]} />
                  )}
                  <div className="plan-button">
                    <>
                      {/* commented for future use */}
                      {/* <Link to={`${ROUTES.PLAN_BILLING}/${getWorkspaceId()}`}>
                        <Button type="text">Compare plans</Button>
                      </Link> */}
                      <div className="plan-button-account-page">
                        {ownerAccess &&
                          lowerCase(item.label!) !== PlanType.FREE && (
                            <PaddleCheckout
                              onPurchaseSuccess={handlePaymentSuccess}
                              onSubscriptionCancel={handleSubscriptionCancel}
                              plan={item}
                              showCancelBtn
                              workspaceId={getWorkspaceId()!}
                            />
                          )}
                      </div>
                    </>
                  </div>
                </div>
              );
            },
          )}
          {loading && <Skeleton active />}
        </div>
        <div className="text-center text-sm mt-36 subscribe-modal-footer-text">
          ZINQ AI is still in beta.
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;
