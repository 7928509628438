import { gql } from '../../../__generated__';

export const GET_SIGNED_URL = gql(`
 query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }}`);

export const GET_PLAN_LIST = gql(`
   query subscriptionPlanKeyFeatures($sort: [ListSubscriptionPlanSort!], $filter: SubscriptionPlanFilter) {
    subscriptionPlanKeyFeatures(sort: $sort, filter: $filter) {
      data {
        id
        keyFeatures
        description
        price
        currency
        type
        title
        label
        recurring
        slug
        workspaceSubscriptionStatus
        subscriptionRenewalDate
        paymentDueAt
        workspaceSubscriptionId
      }
      activeSubscriptionPlan {
        type
      }
    }
}`);

export const GET_FEATURE_LIST = gql(`
  query SubscriptionPlans($sort: [ListSubscriptionPlanSort!]) {
  subscriptionPlans(sort: $sort) {
    data {
      id
      label
      workspaceSubscriptionStatus
      workspaceSubscriptionId
      subscriptionRenewalDate
      paymentDueAt
      features
      currency
      price
      categoryWiseGroupedFeatures {
        categoryKey
        list {
          title
          subCategoryKey
          features {
            value
            title
            key
            featureLabel
            description
            isVisible
            comingSoon
          }
        }
        title
      }
    }
    activeSubscriptionPlan {
      type
      features
      currency
      price
      categoryWiseGroupedFeatures {
        categoryKey
        list {
          title
          subCategoryKey
          features {
            value
            title
            key
            featureLabel
            description
            isVisible
            comingSoon
          }
        }
        title
      }
    }
  }
}`);

export const GET_API_KEYS = gql(`
  query ApiKeys($filter: ListApiKeysFilter, $sort: [ListApiKeysSort!]) {
  apiKeys(filter: $filter, sort: $sort) {
    count
    data {
      apiKeys {
        key
        createdAt
        id
        name
      }
      workspaceMember {
        role
      }
    }
  }
}`);
