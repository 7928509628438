import { useLazyQuery } from '@apollo/client';
import {
  CalendarBlank,
  CaretDown,
  Clock,
  Microphone,
} from '@phosphor-icons/react';
import { Button, ConfigProvider, Divider, Flex, Form, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import { Dayjs } from 'dayjs';
import { filter, map } from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { FormThemeMode, FormType } from '../../../__generated__/graphql';
import { AppContext } from '../../../AppContext';
import {
  DEFAULT_FORM_FIELD_LABEL,
  DROPDOWN_LIST,
  formConfig,
  getSliderMarks,
  REGEX,
  TOAST_MESSAGE,
} from '../../../common/constants';
import {
  commonUploadFile,
  disableTimes,
  formValidation,
  getAcceptedFileTypes,
  getDisabledDates,
  validateFileSize,
} from '../../../common/utils';
import useQueryParams from '../../../hooks/useQueryParams';
import { AppContextType } from '../../../types/appContext.type';
import { FormFieldClassic } from '../editor/fields/FormFieldClassic';
import PagePreview from '../editor/pages/PagePreview';
import {
  AdditionalConfigType,
  CustomFile,
  FormElementType,
  FormFieldType,
  FormPageType,
  FormSchemaType,
  InfoType,
  Palette,
} from '../form.types';
import { GET_SIGNED_URL_UPLOAD } from '../graphql/queries';
import FormHeader from './FormHeader';

type ClassicUIRenderType = {
  itemList: FormSchemaType[];
  activeItemId: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onFinish: (data: any, currentKey?: string) => void; // using any for dynamic values
  activeTheme: Palette;
  handleCTAClick: () => void;
  apiLoader?: boolean;
  isUpload: boolean;
  showFooterButtons: boolean;
  themeMode: FormThemeMode;
  autoFocus: boolean;
  isMobile: boolean;
};

export default function ClassicUIPreview({
  itemList,
  onFinish,
  activeTheme,
  handleCTAClick,
  apiLoader,
  isUpload = false,
  showFooterButtons,
  themeMode,
  autoFocus,
  isMobile = false,
  activeItemId,
}: ClassicUIRenderType) {
  const [form] = useForm();
  const [apiErrors] = useState<Record<string, string>>({});
  const [fileList, setFileList] = useState<Record<string, CustomFile[]>>();
  const { getQueryParam } = useQueryParams();
  const formKey = getQueryParam('formKey') as string;
  const formPreviewKey = getQueryParam('formPreviewKey') as string;
  const {
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const [getSignedUrlMutate, { loading }] = useLazyQuery(
    GET_SIGNED_URL_UPLOAD,
    {
      fetchPolicy: 'network-only',

      onError: () => {},
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnFinish = (formValue: any) => {
    onFinish(formValue);
  };

  const scrollIntoView = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    map(itemList, (item) => {
      if (item?.question_id) {
        const groupFieldCode = form.getFieldValue(
          `${item.question_id},phone,country_code`,
        );
        const singleFieldCode = form.getFieldValue(`${item.question_id}`);
        if (!groupFieldCode) {
          // Only set the value if it doesn't exist
          form.setFieldsValue({
            [`${item.question_id},phone,country_code`]: '+91',
          });
        }
        if (!singleFieldCode) {
          form.setFieldsValue({
            [`${item.question_id},country_code`]: '+91',
          });
        }
      }
    });
    if (activeItemId) {
      scrollIntoView(activeItemId); // scroll to the active item
    }
  }, [itemList, form]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpload = async ({ file, onSuccess, onError, item }: any) => {
    try {
      if (!validateFileSize(file, Number(item?.config?.max_file_size))) {
        onError(
          new Error(
            `You can upload file size upto ${item?.config?.max_file_size}MB`,
          ),
        );
        return true;
      }

      const response = await getSignedUrlMutate({
        variables: {
          data: {
            files: [
              {
                fileName: file.name,
                contentLength: file.size,
              },
            ],
            questionId: item?.answer_store_key as string,
            submissionId: localStorage.getItem(formPreviewKey || formKey) || '',
          },
        },
      });

      const signedUrl =
        response.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]
          ?.signedUrl;
      const key =
        response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;

      if (signedUrl && key) {
        const success = await commonUploadFile(signedUrl, file);
        if (success) {
          onSuccess(response, file);
        } else {
          onError(new Error('Error uploading file!'));
        }
      } else {
        onError(new Error('Failed to get signed URL!'));
      }
    } catch (error) {
      onError(error);
    }
  };

  const generateUploadProps = (item: FormSchemaType) => {
    return {
      accept: getAcceptedFileTypes(item?.config?.file_type),
      name: '',
      multiple: Number(item?.config?.max_file_count) > 1,
      fileList: fileList?.[item.question_id!],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customRequest: (info: any) => {
        if (isUpload) {
          handleUpload({ ...info, item });
        } else {
          if (info.onSuccess) {
            info?.onSuccess('');
          }
        }
      },
      onRemove: () => {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange(info: any) {
        let newFileList = [...info.fileList];
        newFileList = newFileList.map((file) => {
          if (file.response) {
            file.url =
              file.response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;
          }
          return file;
        });
        setFileList({ ...fileList, [item.question_id!]: newFileList });
        form.setFieldValue(
          item.question_id,
          map(newFileList, (file) => file?.url),
        );
      },
    };
  };

  const FooterButtons = ({
    isSubmit = false,
    btnText = 'Submit',
    isDisabled = false,
  }: {
    btnText?: string;
    isSubmit?: boolean;
    isSkip?: boolean;
    isDisabled?: boolean;
  }) => {
    return (
      <Flex justify="space-between" className="pb-60">
        <div></div>
        <div>
          {isSubmit && (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              shape="round"
              loading={loading || apiLoader}
              disabled={isDisabled}
            >
              {btnText}
            </Button>
          )}
        </div>
      </Flex>
    );
  };

  const renderFooterButtons = (type: FormFieldType): ReactNode => {
    switch (type) {
      case FormFieldType.WELCOME_PAGE:
        return null;
      case FormFieldType.END_PAGE:
        return null;
      case FormFieldType.CONTACT_INFO:
      case FormFieldType.ADDRESS:
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.FILE:
      case FormFieldType.LONG_TEXT:
      case FormFieldType.NUMBER:
      case FormFieldType.PHONE_NUMBER:
      case FormFieldType.RATE:
      case FormFieldType.LINEAR_SCALE:
      case FormFieldType.SELECT:
      case FormFieldType.DATE:
      case FormFieldType.TIME:
      case FormFieldType.MULTI_SELECT:
        return <FooterButtons isSubmit />;
      default:
        break;
    }
  };

  const renderItemUI = (
    item: FormSchemaType,
    info: InfoType,
    additionalConfig: AdditionalConfigType,
  ) => {
    const commonRules = item?.is_required ? [formValidation.required] : [];
    const pageType = item?.page_type;
    const type = item?.type;
    switch (type) {
      case FormElementType.FIELD:
        switch (item.field_type) {
          case FormFieldType.CONTACT_INFO:
          case FormFieldType.ADDRESS:
            if (formSettings?.type !== FormType.SmartForm) {
              const renderDataList = filter(
                item?.nested,
                (nestedItem) => !nestedItem.hidden,
              );
              return (
                <Flex vertical gap={32}>
                  <Title className="group-field-title normal">
                    {item.question_text || DEFAULT_FORM_FIELD_LABEL}
                  </Title>
                  <div
                    className={`group-fields ${
                      themeMode === FormThemeMode.Light ? 'light' : ''
                    }`}
                  >
                    {map(renderDataList, (val: FormSchemaType, idx) => {
                      const isLastItem = idx === renderDataList.length - 1;
                      const isLastOdd = idx % 2 === 0 && isLastItem;

                      return (
                        <ConfigProvider
                          theme={{
                            components: {
                              Typography: { fontSizeHeading1: 20 },
                              Form: {
                                itemMarginBottom: 16,
                                labelFontSize: 18,
                                verticalLabelPadding: '0 0 16px 0',
                              },
                            },
                          }}
                        >
                          {!val.hidden && (
                            <div className={isLastOdd ? 'full-width ' : ''}>
                              {renderItemUI(
                                val,
                                {
                                  name: [
                                    item?.question_id as string,
                                    val?.answer_store_key as string,
                                  ],
                                  label:
                                    val?.aiResponse ||
                                    (val?.question_text as string),
                                },
                                {
                                  showFooter: isLastItem,
                                  allowVoice: false,
                                  titleGap: 8,
                                  showRequiredMark: val.is_required || false,
                                },
                              )}
                            </div>
                          )}
                        </ConfigProvider>
                      );
                    })}
                  </div>
                </Flex>
              );
            } else {
              return (
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.SHORT_TEXT}
                  apiErrors={apiErrors}
                  rules={[...commonRules, formValidation.whitespace]}
                  inputProps={{
                    placeholder: 'Type here...',
                    autoSize: { minRows: 1 },
                    showCount: item?.config?.character_limit,
                    maxLength: item?.config?.character_limit,
                    autoFocus: autoFocus,
                    suffix: additionalConfig.allowVoice && (
                      <span className="pointer">
                        <Button type="link" size="small">
                          <Microphone
                            size={18}
                            color={activeTheme?.textColor}
                            weight="fill"
                          />
                        </Button>
                      </span>
                    ),
                    activeTheme: activeTheme,
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required:
                      additionalConfig.showRequiredMark && item?.is_required,
                  }} //using required=false to remove * mark
                />
              );
            }
          case FormFieldType.SHORT_TEXT:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.SHORT_TEXT}
                apiErrors={apiErrors}
                rules={[...commonRules, formValidation.whitespace]}
                inputProps={{
                  placeholder: 'Type here...',
                  autoSize: { minRows: 1 },
                  showCount: item?.config?.character_limit,
                  maxLength: item?.config?.character_limit,
                  autoFocus: autoFocus,
                  suffix: additionalConfig.allowVoice && (
                    <span className="pointer">
                      <Button type="link" size="small">
                        <Microphone
                          size={18}
                          color={activeTheme?.textColor}
                          weight="fill"
                        />
                      </Button>
                    </span>
                  ),
                  activeTheme: activeTheme,
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }} //using required=false to remove * mark
              />
            );
          case FormFieldType.EMAIL:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.SHORT_TEXT}
                apiErrors={apiErrors}
                rules={[
                  ...commonRules,
                  formValidation.email,
                  formValidation.whitespace,
                ]}
                inputProps={{
                  placeholder: 'Type here...',
                  autoSize: { minRows: 1 },
                  autoFocus: autoFocus,
                  maxLength: item?.config?.character_limit,
                  suffix: additionalConfig.allowVoice && (
                    <span className="pointer">
                      <Button type="link" size="small">
                        <Microphone
                          size={18}
                          color={activeTheme?.textColor}
                          weight="fill"
                        />
                      </Button>
                    </span>
                  ),
                  activeTheme: activeTheme,
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }}
              />
            );
          case FormFieldType.FILE:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.FILE}
                apiErrors={apiErrors}
                rules={commonRules}
                inputProps={{
                  maxCount: item?.config?.max_file_count,
                  maxSize: item?.config?.max_file_size,
                  activeTheme: activeTheme,
                  ...generateUploadProps(item),
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }}
              />
            );
          case FormFieldType.LONG_TEXT:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.LONG_TEXT}
                apiErrors={apiErrors}
                rules={[...commonRules, formValidation.whitespace]}
                inputProps={{
                  placeholder: 'Type here...',
                  autoFocus: autoFocus,
                  autoSize: { minRows: 1, maxRows: 5 },
                  showCount: item?.config?.character_limit,
                  maxLength: item?.config?.character_limit,
                  suffix: additionalConfig.allowVoice && (
                    <span className="pointer absolute suffix-icon-textarea">
                      <Button type="link" size="small">
                        <Microphone
                          size={18}
                          color={activeTheme?.textColor}
                          weight="fill"
                        />
                      </Button>
                    </span>
                  ),
                  activeTheme: activeTheme,
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }}
              />
            );
          case FormFieldType.NUMBER:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.NUMBER_TEXT}
                apiErrors={apiErrors}
                rules={[
                  ...commonRules,
                  () => ({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    validator(_: any, value: string) {
                      const min =
                        Number(_?.minValue) || formConfig.defaultMinNumber;
                      const max =
                        Number(_?.maxValue) || formConfig.defaultMaxNumber;

                      const numValue = Number(value);

                      if (value && !REGEX.NUMBER?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(TOAST_MESSAGE.numbersOnly);
                      }
                      if (value) {
                        if (numValue < min || numValue > max) {
                          if (
                            min !== formConfig.defaultMinNumber &&
                            max !== formConfig.defaultMaxNumber
                          ) {
                            return Promise?.reject(
                              `Please enter a number between ${min} and ${max}`,
                            );
                          }
                          if (
                            min === formConfig.defaultMinNumber &&
                            max !== formConfig.defaultMaxNumber
                          ) {
                            return Promise?.reject(
                              `Please enter a number lower than ${max}`,
                            );
                          }
                          if (
                            min !== formConfig.defaultMinNumber &&
                            max === formConfig.defaultMaxNumber
                          ) {
                            return Promise?.reject(
                              `Please enter a number greater than ${min - 1}`,
                            );
                          }
                          if (
                            min === formConfig.defaultMinNumber &&
                            max === formConfig.defaultMaxNumber
                          ) {
                            return Promise?.reject(
                              `Please enter a number lower than ${max}`,
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                    minValue: item?.config?.min_number,
                    maxValue: item?.config?.max_number,
                  }),
                ]}
                inputProps={{
                  placeholder: 'Type here...',
                  autoFocus: autoFocus,
                  controls: false,
                  suffix: additionalConfig.allowVoice && (
                    <span className="pointer absolute suffix-icon-textarea">
                      <Button type="link" size="small">
                        <Microphone
                          size={18}
                          color={activeTheme?.textColor}
                          weight="fill"
                        />
                      </Button>
                    </span>
                  ),
                  activeTheme: activeTheme,
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }}
              />
            );
          case FormFieldType.PHONE_NUMBER:
            return (
              <FormFieldClassic
                name={info?.name}
                label={info?.label}
                type={FormFieldType.NUMBER}
                apiErrors={apiErrors}
                rules={commonRules}
                inputProps={{
                  placeholder: 'Type here...',
                  controls: false,
                  autoFocus: autoFocus,
                  precision: 0,
                  suffix: additionalConfig.allowVoice && (
                    <span className="pointer absolute suffix-icon-textarea">
                      <Button type="link" size="small">
                        <Microphone
                          size={18}
                          color={activeTheme?.textColor}
                          weight="fill"
                        />
                      </Button>
                    </span>
                  ),
                  activeTheme: activeTheme,
                  addonBefore: (
                    <FormFieldClassic
                      name={`${info?.name},country_code`}
                      type={FormFieldType.DROPDOWN}
                      apiErrors={apiErrors}
                      rules={commonRules}
                      inputProps={{
                        placeholder: 'Select',
                        options: DROPDOWN_LIST.countryList,
                        showSearch: true,
                        activeTheme: activeTheme,
                        suffixIcon: (
                          <CaretDown size={18} color={activeTheme?.textColor} />
                        ),
                      }}
                      formItemProps={{
                        className: 'w-full mb-0',
                        required: false,
                      }}
                    />
                  ),
                }}
                formItemProps={{
                  className: 'w-full',
                  required: additionalConfig.showRequiredMark
                    ? item?.is_required
                      ? true
                      : false
                    : false,
                }}
              />
            );
          case FormFieldType.RATE:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.RATE}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Type here...',
                    className: 'w-full',
                    count: item?.config?.max_rating,
                    onChange: (value: string) => {
                      form.setFieldValue(info?.name, value);
                    },
                    activeTheme: activeTheme,
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          case FormFieldType.LINEAR_SCALE:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.LINEAR_SCALE}
                  apiErrors={apiErrors}
                  inputProps={{
                    placeholder: 'Type here...',
                    min: item?.config?.min_rating,
                    max: item?.config?.max_rating,
                    minLabel: item?.config?.min_label,
                    maxLabel: item?.config?.max_label,
                    className: 'w-full',
                    marks: getSliderMarks(Number(item?.config?.max_rating)),
                    activeTheme: activeTheme,
                    onChangeComplete: (value: number) => {
                      form.setFieldValue(info?.name, value);
                    },
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          case FormFieldType.SELECT:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.SELECT}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Type here...',
                    options: item.options ?? [],
                    activeTheme: activeTheme,
                    onChange: (value: string[]) => {
                      form.setFieldValue(info?.name, value);
                    },
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          case FormFieldType.DATE:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.DATE}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Select date',
                    onChange: (value: Dayjs) => {
                      form.setFieldValue(info?.name, value);
                    },
                    suffixIcon: (
                      <CalendarBlank
                        size={20}
                        color={activeTheme?.textColor}
                        weight="fill"
                      />
                    ),
                    disabledDate: (date: Dayjs) =>
                      getDisabledDates(date, {
                        allowFuture: item?.config?.allow_future_date,
                        allowPast: item?.config?.allow_past_date,
                      }),
                    allowFutureDates: item?.config?.allow_future_date,
                    allowPastDates: item?.config?.allow_past_date,
                    activeTheme: activeTheme,
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          case FormFieldType.TIME:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.TIME}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Select time',
                    suffixIcon: (
                      <Clock
                        size={20}
                        color={activeTheme?.textColor}
                        weight="fill"
                      />
                    ),
                    onChange: (val: string, timeString: Dayjs) => {
                      form.setFieldValue(info?.name, timeString);
                    },
                    disabledTime: () => {
                      const minTime = item?.config?.min_time; // e.g., "10:30"
                      const maxTime = item?.config?.max_time; // e.g., "11:40"
                      if (minTime && maxTime) {
                        return disableTimes(minTime, maxTime);
                      }
                    },
                    activeTheme: activeTheme,
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          case FormFieldType.MULTI_SELECT:
            return (
              <Space direction="vertical" className="w-full">
                <FormFieldClassic
                  name={info?.name}
                  label={info?.label}
                  type={FormFieldType.MULTI_SELECT}
                  apiErrors={apiErrors}
                  rules={commonRules}
                  inputProps={{
                    placeholder: 'Type here...',
                    options: item?.options ?? [],
                    activeTheme: activeTheme,
                    onChange: (value: string[]) => {
                      form.setFieldValue(info?.name, value);
                    },
                  }}
                  formItemProps={{
                    className: 'w-full',
                    required: additionalConfig.showRequiredMark
                      ? item?.is_required
                        ? true
                        : false
                      : false,
                  }}
                />
                {/* commenting for future use */}
                {/* {renderItemUI(
                    {
                      ...item,
                      is_required: false,
                      answer_type: FormFieldType.SHORT_TEXT,
                    },
                    {
                      name: 'textResponse',
                      label: '',
                    },
                  )} */}
              </Space>
            );
          default:
            return null;
        }
      case FormElementType.PAGE:
        switch (pageType) {
          case FormPageType.WELCOME_PAGE:
          case FormPageType.END_PAGE:
            return (
              <PagePreview
                item={item}
                pageType={pageType}
                activeTheme={activeTheme}
                handleCTAClick={handleCTAClick}
                isMobile={isMobile}
                allowEditResponse={true}
              />
            );
          default:
            return null;
        }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = (info: any) => {
    if (info?.errorFields?.length > 0) {
      const firstErrorField = info.errorFields?.[0]?.name?.[0];
      const fieldElement = document.getElementById(firstErrorField);
      fieldElement?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderFormHeader = (item: FormSchemaType) => {
    if (
      formSettings?.title &&
      item.page_type !== FormPageType.END_PAGE &&
      item.page_type !== FormPageType.WELCOME_PAGE
    ) {
      return (
        <FormHeader title={formSettings.title} activeTheme={activeTheme} />
      );
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          boxShadow: 'unset',
          fontFamily: activeTheme?.fontFamily,
          colorText: activeTheme?.textColor,
          colorLink: activeTheme?.textColor,
        },
        components: {
          Input: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme?.textColor,
            colorError: activeTheme?.textColor,
            colorErrorText: activeTheme?.textColor,
            boxShadow: 'none',
            colorTextPlaceholder: activeTheme?.textColor,
            colorTextDescription: activeTheme?.textColor,
          },
          Checkbox: {
            paddingContentVertical: 16,
          },
          InputNumber: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme?.textColor,
            colorError: activeTheme?.textColor,
            colorErrorText: activeTheme?.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            activeBorderColor: 'red',
            boxShadowSecondary: 'none',
            hoverBorderColor: 'red',
          },
          Select: {
            colorText: activeTheme?.textColor,
            optionActiveBg: activeTheme?.bgPrimary,
            colorBgElevated: activeTheme?.bgPrimary,
            optionSelectedColor: activeTheme?.bgPrimary,
            optionSelectedBg: activeTheme?.textColor,
          },
          DatePicker: {
            colorBgContainer: 'transparent',
            colorTextPlaceholder: activeTheme?.textColor,
            colorError: activeTheme?.textColor,
            colorErrorText: activeTheme?.textColor,
            borderRadius: 0,
            colorText: activeTheme?.textColor,
            colorBgElevated: activeTheme?.bgPrimary,
            cellHoverBg: `${activeTheme?.textColor}1a`,
            controlItemBgActive: `${activeTheme?.textColor}1a`,
            cellBgDisabled: `${activeTheme?.textColor}1a`,
            colorTextDisabled: `${activeTheme?.textColor}1a`,
            colorIcon: activeTheme?.textColor,
          },
          Form: {
            colorError: activeTheme?.errorColor ?? activeTheme?.textColor,
            // labelFontSize: isMobile ? 20 : 32,
            labelColor: activeTheme?.textColor,
            verticalLabelPadding: '0 0 32px',
            itemMarginBottom: 32,
          },
          Slider: {
            railBg: activeTheme?.textColor,
            colorBgElevated: activeTheme?.textColor,
            railHoverBg: activeTheme?.textColor,
            trackBg: activeTheme?.textColor,
            colorPrimaryBorderHover: activeTheme?.textColor,
            colorText: activeTheme?.textColor,
            colorTextDescription: activeTheme?.textColor,
            railSize: 1,
            handleSize: 16,
            handleSizeHover: 16,
          },
          Button: {
            colorPrimary: activeTheme?.textColor,
            colorPrimaryHover: activeTheme?.textColor,
            colorTextLightSolid: activeTheme?.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
            contentFontSizeLG: isMobile ? 14 : 16,
          },
          Divider: {
            colorSplit: `${activeTheme?.textColor}26`,
          },
        },
      }}
    >
      <Form
        form={form}
        onFinish={handleOnFinish}
        autoComplete="off"
        className="classic-form-renderer form-controls"
        initialValues={{
          countryCode: '+91',
        }}
        onFinishFailed={onFinishFailed}
      >
        {map(itemList, (item, idx) => {
          return (
            <>
              {idx === 0 && renderFormHeader(item)}
              <div id={item?.question_id as string}>
                {renderItemUI(
                  item,
                  {
                    name: item?.question_id as string,
                    label:
                      item?.aiResponse ||
                      (item?.question_text
                        ? item?.question_text
                        : DEFAULT_FORM_FIELD_LABEL),
                  },
                  {
                    showFooter: showFooterButtons,
                    allowVoice: false,
                    titleGap: 32,
                    showRequiredMark: true,
                  },
                )}
                {idx === itemList?.length - 1 ? (
                  <>{renderFooterButtons(item.field_type as FormFieldType)}</>
                ) : (
                  <div className="mt-48 mb-48">
                    <Divider />
                  </div>
                )}
              </div>
            </>
          );
        })}
      </Form>
    </ConfigProvider>
  );
}
