import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Article, Note, Plus, Trash } from '@phosphor-icons/react';
import { Dropdown, Empty, Flex, Popconfirm, Spin, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty, map } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Knowledge,
  KnowledgeType,
  ListKnowledgeSortOnField,
  SortOrder,
} from '../../../../../__generated__/graphql';
import { AppContext } from '../../../../../AppContext';
import {
  DEFAULT_ITEMS_PER_PAGE,
  initialPaginationFilter,
} from '../../../../../common/constants';
import {
  handleGraphQlSuccess,
  hasFeatureAccess,
} from '../../../../../common/utils';
import SubscribeModal from '../../../../../components/common/SubscribeModal';
import CommonButton from '../../../../../components/primitives/CommonButton';
import useFeatureAccess from '../../../../../hooks/useFeatureAccess';
import useRouter from '../../../../../hooks/useRouter';
import { AppContextType } from '../../../../../types/appContext.type';
import { FeatureName, Permissions } from '../../../../../types/common.type';
import { FeatureKeys } from '../../../../profile/profile.types';
import {
  CREATE_FORM_SHARE,
  DELETE_FORM_KNOWLEDGE,
} from '../../../graphql/mutations';
import { GET_FORM_KNOWLEDGES } from '../../../graphql/queries';
import CommonDeleteMessage from '../../utils/CommonDeleteMessage';
import { CommonKnowledgeTypes } from '../pages.types';

export default function KnowledgeList({ onClick }: CommonKnowledgeTypes) {
  const { params } = useRouter();
  const formId = params?.id as string;
  const [knowledges, setKnowledges] = useState<Knowledge[]>([]);
  const {
    state: { formSettings, currentUser },
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);

  const initialSort = {
    sortBy: SortOrder.Desc,
    sortOn: ListKnowledgeSortOnField.CreatedAt,
  };

  const [getFormKnowledges, { loading }] = useLazyQuery(GET_FORM_KNOWLEDGES, {
    variables: {
      filter: { ...initialPaginationFilter, formId },
      sort: initialSort,
    },
    onCompleted: (res) => {
      setKnowledges(res.knowledges?.data?.knowledges as Knowledge[]);
      setHasMore(Number(res.knowledges?.data) > DEFAULT_ITEMS_PER_PAGE);
      setCount(res.knowledges?.count || 0);
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    isUpgradeSuccessfulModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
    closeUpgradeSuccessfulModal,
  } = useFeatureAccess();

  const [createFormShare, { loading: createLoading }] = useMutation(
    CREATE_FORM_SHARE,
    {
      onError: () => {},
    },
  );

  const editAccess = hasPermission(Permissions.WRITE);

  useEffect(() => {
    if (formId) {
      getFormKnowledges();
    }
  }, [formId]);

  const ShareCard = ({
    item,
    hideButtons,
  }: {
    item: Knowledge;
    hideButtons: boolean;
  }) => {
    const [deleteFormKnowledge, { loading: deleteLoading }] = useMutation(
      DELETE_FORM_KNOWLEDGE,
      {
        onError: () => {},
      },
    );

    const handleDelete = (id: string) => {
      deleteFormKnowledge({
        variables: {
          where: {
            knowledgeId: id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.deleteKnowledge?.message);
          setKnowledges(knowledges?.filter((item) => item?.id !== id));
        },
      });
    };

    const isLongText = item?.description && item?.description?.length > 50;
    const displayDescription = isLongText
      ? `${item?.description?.slice(0, 50)}...`
      : item?.description;

    return (
      <Flex
        className="knowledge-card"
        justify="space-between"
        align="center"
        onClick={() => {
          !deleteLoading ? onClick && onClick(item) : undefined;
        }}
      >
        <Flex align="center" gap={12}>
          <CommonButton
            shape="circle"
            size="small"
            icon={<Note size={18} color="var(--content-primary)" />}
          ></CommonButton>
          <Flex vertical gap={4}>
            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
              {item.title}
            </Paragraph>
            <Paragraph className="mb-0">{displayDescription}</Paragraph>
          </Flex>
        </Flex>
        <Flex gap={12} align="center">
          {!hideButtons && editAccess && (
            <Tooltip title="Delete">
              <Popconfirm
                title={
                  <CommonDeleteMessage message="This will delete the knowledge." />
                }
                okText="Yes"
                className="hover-show"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  handleDelete(item.id!);
                }}
                onCancel={(e) => e?.stopPropagation()}
                placement="right"
                okButtonProps={{
                  block: true,
                  icon: <DeleteOutlined />,
                  danger: true,
                }}
                cancelButtonProps={{ block: true }}
                icon={null}
              >
                <CommonButton
                  size="small"
                  type="text"
                  shape="circle"
                  className="bg-surface-tertiary"
                  shadow={false}
                  loading={deleteLoading}
                  onClick={(e) => e.stopPropagation()}
                  icon={<Trash color="var(--danger-on-surface)" size={16} />}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );
  };

  const addKnowledgeList = [
    {
      key: KnowledgeType.Text,
      label: 'Text',
      icon: <Article size={16} />,
    },
  ];

  const handleCreate = () => {
    onClick && onClick();
  };

  // load more func when last element becomes visible
  const loadMore = () => {
    getFormKnowledges({
      variables: {
        filter: {
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: knowledges?.length,
          formId: formId,
        },
        sort: {
          sortBy: SortOrder.Desc,
          sortOn: ListKnowledgeSortOnField.CreatedAt,
        },
      },
      onCompleted: (res) => {
        const mergedData = [
          ...knowledges,
          ...(res.knowledges?.data?.knowledges as Knowledge[]),
        ];
        setKnowledges(mergedData);
        setHasMore(Number(res.knowledges?.data) > mergedData.length);
        setCount(res.knowledges?.count || 0);
      },
    });
  };

  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const hasShareAccess = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.ALPHA_FEATURE,
  );

  return (
    <>
      <Flex className="" vertical>
        <Flex gap={24} className="scroll-wrapper-header" vertical>
          <Flex className="w-full" justify="space-between" align="center">
            <Flex vertical gap={2}>
              <Paragraph className="mb-0 text-m semi-bold text-content-primary">
                Form Knowledge
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Add a knowledge to setup knowledge settings.
              </Paragraph>
            </Flex>
            <Dropdown
              disabled={createLoading || !editAccess}
              menu={{
                items: addKnowledgeList,
                onClick: ({ key }) => {
                  checkFeatureAccess(FeatureKeys.ALPHA_FEATURE, () => {
                    handleCreate();
                  });
                },
              }}
              placement="bottom"
              trigger={['click']}
            >
              <CommonButton
                type="text"
                icon={<Plus size={14} />}
                premium={!hasShareAccess}
                loading={createLoading}
              >
                Add
              </CommonButton>
            </Dropdown>
          </Flex>
        </Flex>
        <div className="scroll-wrapper-body">
          {/* Loading state */}
          <Spin spinning={loading}>
            <Flex vertical gap={16}>
              {map(knowledges, (item: Knowledge, idx) => {
                return (
                  <div
                    ref={idx === knowledges?.length - 1 ? lastItemRef : null}
                  >
                    <ShareCard item={item} hideButtons={!hasShareAccess} />
                  </div>
                );
              })}
              {loading && <Spin spinning />}
            </Flex>
          </Spin>

          {/* Empty state */}
          {isEmpty(knowledges) && !loading && (
            <Empty description="No knowledge found" />
          )}
        </div>
      </Flex>

      {isSubscriptionModalVisible && (
        <SubscribeModal
          visible={isSubscriptionModalVisible}
          onCancel={closeSubscriptionModal}
          onUpgrade={handleUpgradeSuccess}
          featureName={FeatureName.MultiFormLink}
        />
      )}
      {/* {isUpgradeSuccessfulModalVisible && (
        <UpgradeSuccessful
          isVisible={isUpgradeSuccessfulModalVisible}
          onClose={closeUpgradeSuccessfulModal}
        />
      )} */}
    </>
  );
}
