import { useLazyQuery, useMutation } from '@apollo/client';
import { CaretLeft } from '@phosphor-icons/react';
import { Col, ConfigProvider, Flex, Form, Input, Row, Spin } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useEffect } from 'react';
import {
  formValidation,
  handleGraphQlError,
  handleGraphQlSuccess,
} from '../../../../../common/utils';
import CommonButton from '../../../../../components/primitives/CommonButton';
import useRouter from '../../../../../hooks/useRouter';
import { ADD_FORM_KNOWLEDGE } from '../../../graphql/mutations';
import { GET_KNOWLEDGE_DETAIL } from '../../../graphql/queries';
import { CommonKnowledgeTypes } from '../pages.types';

export default function AddKnowledge({
  onBack,
  knowledgeId,
}: CommonKnowledgeTypes) {
  const [form] = Form.useForm();
  const { params } = useRouter();
  const formId = params?.id as string;

  const [createKnowledgeMutate, { loading }] = useMutation(ADD_FORM_KNOWLEDGE, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      handleGraphQlError(error?.message);
    },
  });

  const [fetchKnowledge, { loading: knowledgeLoading }] = useLazyQuery(
    GET_KNOWLEDGE_DETAIL,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        form.setFieldsValue(res.knowledge?.data?.knowledge);
      },
      onError: (error) => {
        handleGraphQlError(error?.message);
      },
    },
  );

  const handleOnFinish = () => {
    if (formId) {
      createKnowledgeMutate({
        variables: {
          data: {
            ...form.getFieldsValue(),
            formId,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.createKnowledge?.message);
          onBack();
        },
      });
    }
  };

  useEffect(() => {
    if (knowledgeId) {
      fetchKnowledge({
        variables: {
          where: {
            knowledgeId,
          },
        },
      });
    } else {
      form.resetFields();
    }
  }, [knowledgeId]);

  const commonRules = [formValidation.required, formValidation.whitespace];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#a9aeb1',
        },
        components: {
          Form: {
            labelColor: 'var(--content-secondary)',
            labelFontSize: 14,
            verticalLabelPadding: 0,
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Input: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
            colorText: 'var(--content-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          InputNumber: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          Select: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            optionActiveBg: 'var(--surface-tertiary)',
            optionSelectedBg: 'var(--surface-tertiary)',
            colorBgContainer: '#ff0000',
          },
          Tag: {
            borderRadius: 16,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Spin spinning={knowledgeLoading}>
        <Form
          autoComplete="off"
          form={form}
          className="edit-template-form"
          onFinish={handleOnFinish}
          disabled={!!knowledgeId}
        >
          <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
            <CommonButton
              type="text"
              onClick={() => onBack()}
              shape="circle"
              shadow={false}
              disabled={false}
              icon={<CaretLeft color="var(--content-primary)" size={20} />}
            />
            <Paragraph className="mb-0 text-content-primary text-base semi-bold">
              Add Knowledge
            </Paragraph>
          </Flex>
          <div className="scroll-wrapper-body">
            <Row gutter={[12, 24]}>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  labelCol={{ span: 24 }}
                  rules={commonRules}
                >
                  <Input placeholder="Type here..." maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  labelCol={{ span: 24 }}
                  rules={commonRules}
                >
                  <Input.TextArea
                    placeholder="Type here..."
                    autoSize={{ minRows: 3, maxRows: 14 }}
                    maxLength={500}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="text"
                  label="Text"
                  labelCol={{ span: 24 }}
                  rules={commonRules}
                >
                  <Input.TextArea
                    placeholder="Type here..."
                    autoSize={{ minRows: 10 }}
                  />
                </Form.Item>
              </Col>
              {!knowledgeId && (
                <Col span={24}>
                  <CommonButton
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                  >
                    Save
                  </CommonButton>
                </Col>
              )}
            </Row>
          </div>
        </Form>
      </Spin>
    </ConfigProvider>
  );
}
